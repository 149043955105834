import { LocalShippingTwoTone } from "@mui/icons-material";
import { ReactElement } from "react";
import { DeliveryOptionProps } from "../../../Types/CheckoutType";
import AccordionRow from "../../AccordionRow";
import DeliveryOptionsForm from "./DeliveryOptionsForm";
import DeliveryOptionsSet from "./DeliveryOptionsSet";

function DeliveryOptions({
	appState,
	setAppState,
	sessionData,
	deliveryOptions,
	selectedDeliveryOption,
	stateId,
}: DeliveryOptionProps): ReactElement {
	function setToEdit() {
		setAppState({ currentState: stateId, type: "EDIT" });
	}

	function setToComplete() {
		setAppState({ currentState: stateId, type: "SET" });
	}

	const currentAppState = appState[stateId].state;

	return (
		<AccordionRow
			state={currentAppState}
			icon={
				<LocalShippingTwoTone fontSize="medium"></LocalShippingTwoTone>
			}
			typography={"Delivery Options"}
			expandable={
				currentAppState !== "CURRENT" &&
				currentAppState !== "EDIT" &&
				currentAppState !== "FUTURE"
			}
			expandOnClick={setToEdit}
		>
			{(currentAppState === "CURRENT" || currentAppState === "EDIT") && (
				<DeliveryOptionsForm
					deliveryOptions={deliveryOptions}
					sessionData={sessionData}
					onUpdateSuccess={setToComplete}
				/>
			)}

			{currentAppState === "SET" && (
				<DeliveryOptionsSet
					selectedDeliveryOption={
						deliveryOptions[selectedDeliveryOption ?? 0]
					}
				/>
			)}
		</AccordionRow>
	);
}

export default DeliveryOptions;
