import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { CheckoutAction, CheckoutId } from "../../../Types/CheckoutType";
import AddButton from "../../AddButton";

const VoucherForm = ({
	setAppState,
	currentStateId,
	setModelOpen,
}: {
	setAppState: React.Dispatch<CheckoutAction>;
	currentStateId: CheckoutId;
	setModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	return (
		<Box display="flex" flexDirection="column">
			<AddButton
				text="Add new gift card or voucher code"
				onClick={() => {
					setModelOpen((prevState) => !prevState);
				}}
			/>

			<Button
				style={{
					color: "white",
					backgroundColor: "#2E7D32",
					textAlign: "center",
					marginTop: "1em",
				}}
				onClick={() => setAppState({ currentState: currentStateId, type: "SET" })}
			>
				Continue
			</Button>
		</Box>
	);
};

export default VoucherForm;
