import { ReportOpts } from "web-vitals";

const reportWebVitals = (onPerfEntry?: ReportOpts) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals").then(({ onCLS, onINP, onFCP, onLCP, onTTFB }) => {
      onCLS(console.log, onPerfEntry);
      onINP(console.log, onPerfEntry);
      onFCP(console.log, onPerfEntry);
      onLCP(console.log, onPerfEntry);
      onTTFB(console.log, onPerfEntry);
    });
  }
};

export default reportWebVitals;
