import { QueryClient, useMutation } from "react-query";
import { Address } from "../../Types/AddressTypes";
import { onErrorFunction, onSuccessFunction } from "../../Types/MutationTypes";
import { updateSessionAddress } from "../../Utilities/addressFunctions";

export const useUpdateAddressMutation = (
	queryClient: QueryClient,
	onError?: onErrorFunction,
	onSuccess?: onSuccessFunction
) => {
	const updateAddressMutation = useMutation(
		async ({
			address,
			addressIndex,
		}: {
			address: Address;
			addressIndex: number;
		}) => {
			return updateSessionAddress(address, addressIndex!);
		},
		{
			onSuccess: (data) => {
				queryClient.setQueryData("session", data);
				if (onSuccess) {
					onSuccess(data);
				}
			},
			onError: (data) => {
				if (onError) {
					onError(data);
				}
			},
		}
	);

	return {
		updateAddressMutation: updateAddressMutation.mutateAsync,
	};
};
