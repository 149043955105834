import { captureException } from "@sentry/browser";
import axios from "axios";
import { Address } from "../Types/AddressTypes";
import {
	ErrorResponseType,
	PayRequestType,
	SavedCardRequestType,
} from "../Types/BackendRequestTypes";
import { Session } from "../Types/SessionType";
import { formatBackendErrors } from "./backendErrorFunctions";

export const setAxiosInterceptors = (token: string) => {
	axios.interceptors.request.use((request) => {
		//Always send our cookies
		request.withCredentials = true;

		return request;
	});

	axios.interceptors.request.use((config) => {
		config.params = { ...config.params, token: token };
		return config;
	});
};

export const verifyVoucher = async (code: string): Promise<Session> => {
	code = code.trim();
	log("vocher-redeem", "function", { code: code });
	const { data } = await axios.post(
		process.env.REACT_APP_BACKEND_API_URL + "/session/voucher/" + encodeURI(code)
	);

	return data.data.session;
};

export const getCompleteOrderToken = async (): Promise<{
	successUrl: string;
	token: string;
}> => {
	const response = await axios.get(
		process.env.REACT_APP_BACKEND_API_URL + "/session/complete-order-token"
	);

	return response.data.data;
};

export const updateSelectedDeliveryOption = async (
	currentDeliveryOption: number
): Promise<Session> => {
	log("update-selected-delivery-address", "function", {
		index: currentDeliveryOption,
	});
	const response = await axios.put(
		process.env.REACT_APP_BACKEND_API_URL + "/session/delivery-option",
		{
			index: currentDeliveryOption,
		}
	);

	return response.data.data.session;
};

export const addNewAddress = async (
	address: Address,
	setAsBillingAddress: boolean,
	setAsDeliveryAddress: boolean
): Promise<Session> => {
	log("add-new-address", "function", {
		address: address,
		setAsDeliveryAddress: setAsDeliveryAddress,
		setAsBillingAddress: setAsBillingAddress,
	});
	const response = await axios.post(
		process.env.REACT_APP_BACKEND_API_URL + "/session/address",
		{
			address: address,
			setAsSelectedBillingAddress: setAsBillingAddress,
			setAsSelectedDeliveryAddress: setAsDeliveryAddress,
		}
	);

	return response.data.data.session;
};

export const getClientTokenFromBackend = async (
	userId: number,
	token: string
) => {
	axios
		.get(
			process.env.REACT_APP_BACKEND_API_URL +
				"/session/clientToken/" +
				userId +
				"/" +
				token
		)
		.then((data) => {
			return data.data.data.clientToken;
		})
		.catch((error) => {
			console.log(
				"There was an error getting the braintree client token",
				error
			);
		});
};

export const updateSelectedDonationOptions = async (optionIndex: number) => {
	log("update-selected-donation-option", "function", { index: optionIndex });
	const { data } = await axios.put(
		process.env.REACT_APP_BACKEND_API_URL + "/session/donation/option",
		{
			index: optionIndex,
		}
	);

	return data.data.session;
};

export const createDonationOption = async (
	optionIndex: number,
	amount: number,
	text: string
): Promise<Session> => {
	log("create-donation-option", "function", {
		index: optionIndex,
		amout: amount,
		text: text,
	});
	const { data } = await axios.post(
		process.env.REACT_APP_BACKEND_API_URL + "/session/donation/option",
		{
			amount: amount,
			text: text,
			index: optionIndex,
		}
	);

	return data.data.session;
};

export const updateDonationGiftAid = async (
	giftAid: boolean
): Promise<Session> => {
	log("update-donation-gift-aid", "function", { giftAid: giftAid });
	const response = await axios.put(
		process.env.REACT_APP_BACKEND_API_URL + "/session/donation/gift-aid",
		{ giftAid }
	);

	return response.data.data.session;
};

export const pay = async (
	nonce: string,
	sessionData: Session,
	deviceData?: string | null,
	saveCard: boolean = false,
	billingReference: string = "",
	sendEmail: boolean = true,
) => {
	log("pay", "function", {
		nonce: nonce,
		sessionData: sessionData,
		deviceData: deviceData,
	});
	const realPayment = true;

	if (!realPayment) {
		alert("Payments Not allowed");
		return;
	}

	const user = sessionData.user;
	const billingAddress =
		sessionData.addresses[sessionData.selectedBillingAddress ?? 0];
	const requestData: PayRequestType = {
		nonce,
		deviceData: deviceData ?? "no device data",
		amount: sessionData.costs.total,
		billingAddress,
		saveCard,
		submitForSettlement: sessionData.settings.submitForSettlement,
		billingReference,
		sendEmail
	};

	if (!sessionData.settings.threeDSecure) {
		requestData.enable3dSecure = false;
	}

	if (sessionData.settings.transactionSource !== null) {
		requestData.source = sessionData.settings.transactionSource;
	}

	if (user.id !== null) {
		requestData.customer = {
			id: user.id,
			email: user.email ?? "",
		};
	}

	const { data } = await axios.post(
		process.env.REACT_APP_BACKEND_API_URL + "/session/v2/pay",
		requestData
	);

	const response = data.data;

	window.onbeforeunload = () => {}; //Just before make sure we can redirect
	const url = new URL(response.successUrl);
	url.searchParams.append('token', response.token);
	window.location.href = url.toString();
};

export const verifySession = async (paymentMethod: string) => {
	const response = await axios.post(
		process.env.REACT_APP_BACKEND_API_URL + "/session/verify",
		{ paymentMethod }
	);

	if (response.status !== 200) {
		return false;
	}

	return true;
};

export const onCompleteEvent = async () => {
	const response = await axios.post(
		process.env.REACT_APP_BACKEND_API_URL + "/session/complete",
	);

	if (response.status !== 200) {
		return false;
	}

	return true;
};

export const getTransactionIdByNonce = async (
	nonce: string
): Promise<number> => {
	try {
		const { data } = await axios.get(
			process.env.REACT_APP_BACKEND_API_URL +
				"/session/transaction/nonce/" +
				nonce
		);
		return data.data.transaction.id;
	} catch {
		return 0;
	}
};

export const updatePaymentDetails = async (
	transactionId: number,
	billingReference: string,
	sendEmail: boolean
): Promise<Session> => {
	const { data } = await axios.put(
		process.env.REACT_APP_BACKEND_API_URL + "/session/payment-details",
		{ transactionId, billingReference, sendEmail }
	);

	return data.data.session;
};

export const getSavedCards = async (): Promise<{
	savedCards: SavedCardRequestType[];
	default: string;
}> => {
	const { data } = await axios.get(
		process.env.REACT_APP_BACKEND_API_URL + "/session/card"
	);

	return data.data;
};

export const updateUserAsGuest = async (email: string) => {
	log("update-user-as-guest", "function", { email: email });
	const { data } = await axios.put(
		process.env.REACT_APP_BACKEND_API_URL + "/session/user",
		{
			email,
		}
	);

	return data.data.session;
};

export const updateUserPhoneNumber = async (
	phoneNumber: string
): Promise<Session> => {
	log("update-user-phone-number", "function", { phoneNumber: phoneNumber });
	const { data } = await axios.put(
		process.env.REACT_APP_BACKEND_API_URL + "/session/user/phone",
		{
			phoneNumber,
		}
	);

	return data.data.session;
};

export const getNonceFromSavedCard = async (
	cardId: string
): Promise<{nonce: string, bin: string}> => {
	const { data } = await axios.get(
		process.env.REACT_APP_BACKEND_API_URL +
			"/session/card/" +
			cardId +
			"/nonce"
	);

	return {
		nonce: data.data.nonce,
		bin: data.data.bin,
	};
};

export const deleteSavedCard = async (
	cardId: string
): Promise<boolean> => {
	try {
		await axios.delete(
			process.env.REACT_APP_BACKEND_API_URL + "/session/card/" + cardId
		)
		return true;
	} catch {
		return false;
	}
};

export const addGiftMessage = async (giftMessage: string): Promise<Session> => {
	log("add-gift-message", "function", { message: giftMessage });
	const { data } = await axios.post(
		process.env.REACT_APP_BACKEND_API_URL + "/session/gift-message",
		{
			giftMessage,
		}
	);

	return data.data.session;
};

export const logout = async () => {
	log("logout", "function", {});
	const { data } = await axios.put(
		process.env.REACT_APP_BACKEND_API_URL + "/session/user/logout"
	);
	const session = data.data.session as Session;

	return session;
};

export const log = async (
	action: string,
	actionType: string,
	context: object
) => {
	axios.post(process.env.REACT_APP_BACKEND_API_URL + "/session/log", {
		action: action,
		actionType: actionType,
		context: context,
	}).catch((error) => {
		//If it's a 400 then just leave it
		if(error.response && error.response.status && (error.response.status !== 400 || error.response.status !== 401)) {
			captureException(error)
		}
		throw error;
	});
};

export const getDeliveryOptions = async (
	countryCode: string,
	postcode: string
): Promise<Session> => {
	const { data } = await axios.get(
		process.env.REACT_APP_BACKEND_API_URL +
			`/session/delivery-option/${countryCode}/${postcode}`
	);

	return data.data.session;
};

export const updateDeliveryDetails = async (
	phoneNumber: string,
	giftMessage: string,
	addressIndex: number
) => {
	const { data } = await axios.put(
		process.env.REACT_APP_BACKEND_API_URL + "/session/delivery-details",
		{
			phoneNumber: phoneNumber,
			giftMessage: giftMessage,
			deliveryAddressIndex: addressIndex,
		}
	);

	return data.data.session;
};

export const handleAxiosError = (
	setErrors: React.Dispatch<React.SetStateAction<string[]>>
) => {
	return {
		onError: (error: any) => {
			if (
				axios.isAxiosError(error) &&
				error.response?.data &&
				typeof error.response.data === "object" &&
				"errors" in error.response.data
			) {
				let errors = formatBackendErrors(
					error.response.data as ErrorResponseType
				);
				log("axios-error", "function", {
					error: error.response.data,
					status: error.response.status,
				});

				if (error.response.status >= 500) {
					captureException(error);
				}

				setErrors(errors);
			} else {
				captureException(error);
				log("axios-error-unknown", "function", {
					error: "Unknown",
					details: error
				});
				setErrors([
					"Unknown error please try again or contact customer service",
				]);
			}
		},
	};
};

export const axiosError = (
	setErrors: React.Dispatch<React.SetStateAction<string[]>>,
	error: any
) => {
	if (
		axios.isAxiosError(error) &&
		error.response?.data &&
		typeof error.response.data === "object" &&
		"errors" in error.response.data
	) {
		let errors = formatBackendErrors(
			error.response.data as ErrorResponseType
		);
		log("axios-error", "function", {
			error: error.response.data,
			status: error.response.status,
		});

		if (error.response.status >= 500) {
			captureException(error);
		}

		setErrors(errors);
	} else {
		captureException(error);
		log("axios-error-unknown", "function", {
			error: "Unknown",
			details: error
		});
		setErrors([
			"Unknown error please try again or contact customer service",
		]);
	}
};

export const getIpFor3ds = async () => {
	const { data } = await axios.get(
		process.env.REACT_APP_BACKEND_API_URL +
			"/session/ip3ds"
	);

	return data.ip3ds;
}
