import { QueryClient, useMutation } from "react-query";
import { onErrorFunction, onSuccessFunction } from "../../Types/MutationTypes";
import { updateDeliveryDetails } from "../../Utilities/backendRequests";

export const useUpdateDeliveryDetailsMutation = (
	queryClient: QueryClient,
	onError?: onErrorFunction,
	onSuccess?: onSuccessFunction
) => {
	const updateDeliveryDetailsMutation = useMutation(
		async ({
			phoneNumber,
			giftMessage,
			selectedAddressIndex,
		}: {
			phoneNumber: string;
			giftMessage: string;
			selectedAddressIndex: number;
		}) => {
			return updateDeliveryDetails(phoneNumber, giftMessage, selectedAddressIndex);
		},
		{
			onSuccess: (data) => {
				queryClient.setQueryData("session", data);
				if (onSuccess) {
					onSuccess(data);
				}
			},
			onError: (data) => {
				if (onError) {
					onError(data);
				}
			},
		}
	);

	return {
		updateDeliveryDetails: updateDeliveryDetailsMutation.mutateAsync,
	};
};
