import braintree from "braintree-web";
import { useEffect, useState } from "react";
import { PaypalBraintreeHookType } from "../Types/BraintreePaypalTypes";

export const usePaypalBraintree = (
	braintreeClient: braintree.Client | null
) => {
	const [paypalBraintree, setPaypalBraintree] =
		useState<PaypalBraintreeHookType>({
			braintreePaypalClient: null,
			paypalFetching: true,
		});

	useEffect(() => {
		async function getPaypalInfo(braintreeClient: braintree.Client) {
			const braintreePaypalClient = await braintree.paypalCheckout.create(
				{
					client: braintreeClient,
				}
			);

			const paypalSDK = await braintreePaypalClient.loadPayPalSDK({
				currency: "GBP",
				intent: "authorize",
			});

			setPaypalBraintree({
				braintreePaypalClient: paypalSDK,
				paypalFetching: false,
			});
		}

		if (null !== braintreeClient) {
			getPaypalInfo(braintreeClient);
		}
	}, [braintreeClient]);

	return paypalBraintree;
};
