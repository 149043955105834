import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, TextField } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as Yup from "yup";
import { useAddAddressMutation } from "../../../Hooks/Mutations/useAddAddressMutation";
import { useErrorMessage } from "../../../Hooks/useErrorMessage";
import { useGiftMessage } from "../../../Hooks/useGiftMessage";
import { Address, LoqateAddressLimited } from "../../../Types/AddressTypes";
import { Session } from "../../../Types/SessionType";
import {
	loquateAddressListFromAddressId,
	loquateAddressListFromPostcode,
	translateLoqateAddress,
} from "../../../Utilities/addressFunctions";
import { axiosError, log } from "../../../Utilities/backendRequests";
import GreenButton from "../../GreenButton";
import ManualAddressDialog from "./ManualAddressDialog";
import PostcodeFinderDialog from "./PostcodeFinderDialog";

interface FindMyAddressFormProps {
	sessionData: Session;
	onBackToAddressBook: Function;
	onComplete: (
		selectedAddressIndex: number,
		giftMessage: string,
		phoneNumber: string
	) => Promise<void>;
}

function FindMyAddressForm({
	sessionData,
	onBackToAddressBook,
	onComplete,
}: FindMyAddressFormProps) {
	const queryClient = useQueryClient();
	const { errorMessage, setErrors, clearErrors } = useErrorMessage({
		margin: "1em 0",
	});
	const onMutationErrorHandler = (response: any) => {
		axiosError(setErrors, response);
	};

	const { addAddress } = useAddAddressMutation(
		queryClient,
		onMutationErrorHandler
	);
	const [postcodeFinderState, setPostcodeFinderState] = useState(false);
	const [manualAddress, setManualAddress] = useState(false);
	const [addresses, setAddresses] = useState<LoqateAddressLimited[]>([]);
	const { getGiftMessage, giftMessageComponent } =
		useGiftMessage(sessionData);

	const {
		register,
		formState: { errors, isSubmitting },
		watch,
		handleSubmit,
	} = useForm({
		resolver: yupResolver(
			Yup.object().shape({
				phoneNumber: Yup.string().matches(
					/^[0-9 ]*$/,
					"Only numbers allowed"
				).optional(),
				fullName: Yup.string().required("Full name is required"),
				postcode: Yup.string().required("Postcode is required"),
			})
		),
		defaultValues: { phoneNumber: sessionData.user.phoneNumber },
	});

	return (
		<Box display="flex" gap="1em" flexDirection="column" mt="1em">
			{errorMessage}
			<TextField
				label="Full Name"
				{...register("fullName")}
				error={!!errors.fullName}
				helperText={errors.fullName?.message ?? ""}
				data-testid="findAddressName"
			></TextField>
			<TextField
				label="Mobile Phone"
				{...register("phoneNumber")}
				error={!!errors.phoneNumber}
				helperText={
					errors.phoneNumber?.message ??
					"Used only for delivery purpose"
				}
				data-testid="findAddressNumber"
			></TextField>
			<TextField
				label="Postcode"
				{...register("postcode")}
				error={!!errors.postcode}
				helperText={
					!!errors.postcode
						? errors.postcode?.message ?? ""
						: "Only UK addresses"
				}
				data-testid="findAddressPostcode"
			></TextField>

			<GreenButton
				loading={isSubmitting}
				disabled={isSubmitting}
				text="Find My Address"
				data-testid="btnFindAddress"
				onClick={handleSubmit(async (data) => {
					clearErrors();
					log("find-my-address", "click", { data: data });
					const loquateAddresses =
						await loquateAddressListFromPostcode(data.postcode);
					setAddresses(loquateAddresses);
					setPostcodeFinderState(true);
				})}
			/>
			<Divider>or</Divider>
			<GreenButton
				style={{
					color: "#2E7D32",
					backgroundColor: "white",
					border: "1px solid rgba(46, 125, 50, 0.5)",
				}}
				text="Enter Address Manually"
				onClick={() => {
					log("enter-manual-address", "click", {});
					setManualAddress(true);
				}}
			/>

			{postcodeFinderState && (
				<PostcodeFinderDialog
					open={postcodeFinderState}
					closeDialog={() => {
						setPostcodeFinderState(false);
					}}
					postcodeToSearch={watch("postcode")}
					addresses={addresses}
					setAddresses={setAddresses}
					onAddressSelect={async (addressId) => {
						clearErrors();
						log("postcode-finder", "click", {
							addressId: addressId,
						});
						const addresses = await loquateAddressListFromAddressId(
							addressId
						);
						const formattedAddress = translateLoqateAddress(
							addresses[0],
							watch("fullName")
						);

						const data = await addAddress({
							address: formattedAddress,
						});

						if (data.selectedDeliveryAddress !== null) {
							await onComplete(
								data.selectedDeliveryAddress,
								getGiftMessage(),
								watch("phoneNumber") ?? ""
							);
						}
					}}
				/>
			)}

			{manualAddress && (
				<ManualAddressDialog
					open={manualAddress}
					handleClose={() => {
						setManualAddress(false);
					}}
					onAddressEntered={async (manualAddress: Address) => {
						clearErrors();
						const data = await addAddress({
							address: manualAddress,
						});
						if (data.selectedDeliveryAddress !== null) {
							await onComplete(
								data.selectedDeliveryAddress,
								getGiftMessage(),
								watch("phoneNumber") ?? ""
							);
						}
					}}
					countryCodes={sessionData.acceptedDeliveryCountries}
				/>
			)}

			{sessionData.addresses.length > 0 && (
				<GreenButton
					text="Back to address book"
					style={{
						color: "#2E7D32",
						backgroundColor: "white",
						border: "1px solid rgba(46, 125, 50, 0.5)",
					}}
					onClick={() => onBackToAddressBook()}
				/>
			)}

			{giftMessageComponent}
		</Box>
	);
}

export default FindMyAddressForm;
