import { Button, ButtonProps, CircularProgress } from "@mui/material";

interface GreenButtonProps {
	text: string;
	loading?: boolean;
	loadingStyle?: React.CSSProperties;
}

type GreenButtonType = GreenButtonProps & ButtonProps;

const defaultStyles: React.CSSProperties = {
	color: "white",
	backgroundColor: "#2E7D32",
	width: "100%",
	textAlign: "center",
};

const defaultLoadingStyles: React.CSSProperties = {
	color: "white",
	marginRight: "0.5em",
};

const GreenButton = ({
	text,
	loading = false,
	style = {},
	loadingStyle = {},
	...props
}: GreenButtonType) => {
	if (props.disabled) {
		defaultStyles.opacity = 0.5;
	} else {
		defaultStyles.opacity = 1;
	}

	return (
		<Button style={{ ...defaultStyles, ...style }} {...props}>
			{loading && (
				<CircularProgress
					size={14}
					style={{ ...defaultLoadingStyles, ...loadingStyle }}
				/>
			)}
			{text}
		</Button>
	);
};

export default GreenButton;
