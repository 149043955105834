import { Close } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogTitle,
	Divider,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import { CSSProperties, ReactElement, useState } from "react";
import { useForm } from "react-hook-form";
import { useDialogWidth } from "../../../Hooks/useDialogWidth";
import { iconBorderStyle } from "../../../Styles/IconStyle";
import { LoqateAddressLimited } from "../../../Types/AddressTypes";
import { loquateAddressListFromPostcode } from "../../../Utilities/addressFunctions";
import { log } from "../../../Utilities/backendRequests";
import ErrorMessage from "../../ErrorMessage";
import GreenButton from "../../GreenButton";
import AddressLimitedRadioList from "./AddressLimitedRadioList";

const searchButtonStyle: CSSProperties = {
	border: "1px solid rgba(25, 118, 210, 0.5)",
	borderRadius: 4,
	color: "#1976D2",
	height: 42,
	fontWeight: 600,
};

interface DeliveryAddressModalProps {
	open: boolean;
	closeDialog: () => void;
	postcodeToSearch: string;
	addresses: LoqateAddressLimited[];
	setAddresses: React.Dispatch<React.SetStateAction<LoqateAddressLimited[]>>;
	onAddressSelect: (addressId: string) => Promise<void>;
}

function PostcodeFinderDialog({
	open,
	closeDialog,
	postcodeToSearch,
	addresses,
	setAddresses,
	onAddressSelect,
}: DeliveryAddressModalProps): ReactElement {
	const dialogWidth = useDialogWidth();
	const [fetching, setFetching] = useState(false);
	const [selectedAddressId, setSelectedAddressId] = useState<string | null>(
		null
	);

	const {
		register,
		formState: { errors },
		handleSubmit,
		watch,
	} = useForm<{
		postcodeSearch: string;
		fullName: string;
	}>({
		defaultValues: { postcodeSearch: postcodeToSearch },
		reValidateMode: "onSubmit",
	});

	return (
		<Dialog open={open} onClose={closeDialog} {...dialogWidth}>
			<DialogTitle>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<Typography fontWeight={600}>Select Address</Typography>
					<IconButton onClick={closeDialog} style={iconBorderStyle}>
						<Close />
					</IconButton>
				</Box>
			</DialogTitle>

			<Divider />

			<Box
				display="flex"
				gap="1em"
				alignItems="center"
				padding="1em 1em 0 1em"
			>
				<TextField
					label="Search by postcode"
					style={{ flexGrow: 1 }}
					{...register("postcodeSearch", { required: true })}
					error={!!errors.postcodeSearch}
					data-testid="postcodeFinderSearch"
				></TextField>
				<Button
					style={searchButtonStyle}
					onClick={handleSubmit(async (data) => {
						log("postcode-finder-search", "click", { data: data });
						const results = await loquateAddressListFromPostcode(
							data.postcodeSearch
						);

						setAddresses(results);
					})}
				>
					Search
				</Button>
			</Box>
			<ErrorMessage
				style={{ marginLeft: "2em" }}
				errorMessage={
					!!errors.postcodeSearch ? "Postcode is required" : ""
				}
			/>

			{addresses.length !== 0 && (
				<>
					<Typography
						fontWeight={600}
						color={"#00000099"}
						fontSize={"0.9em"}
						style={{ padding: "1em" }}
					>
						We found {addresses.length} addresses - The address can
						be edited later
					</Typography>
					<AddressLimitedRadioList
						addresses={addresses}
						selectedAddress={selectedAddressId}
						onAddressChange={(selectedAddressId: string) => {
							setSelectedAddressId(selectedAddressId);
						}}
					/>

					<Box padding="0.5em ">
						<GreenButton
							data-testid="postcodeFinderSet"
							text="Set Address"
							loading={fetching}
							disabled={selectedAddressId === "" || fetching}
							onClick={async () => {
								if (selectedAddressId !== null) {
									setFetching(true);

									try {
										await onAddressSelect(
											selectedAddressId
										);
									} catch (error) {
										setFetching(false);
										closeDialog();
										return;
									}

									setFetching(false);
									closeDialog();
								}
							}}
						/>
					</Box>
				</>
			)}

			{addresses.length === 0 && (
				<Typography
					fontWeight={600}
					color={"#00000099"}
					fontSize={"0.9em"}
					style={{ padding: "1em" }}
				>
					No address found for {watch("postcodeSearch")}
				</Typography>
			)}
		</Dialog>
	);
}

export default PostcodeFinderDialog;
