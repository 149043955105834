import { Backdrop, Box, Divider } from "@mui/material";
import { useState } from "react";
import { ExpressCheckoutProps } from "../../../Types/CheckoutType";
import { Session } from "../../../Types/SessionType";
import ApplePayButton, { ApplePayExpress } from "../Payment/ApplePayButton";
import GooglePayButton, {
	ExpressGooglePayButton,
} from "../Payment/GooglePayButton";
import { ExpressPaypal } from "../Payment/PaypalButton";
import ProcessingPaymentMessage from "./ProcessingPaymentMessage";

const ExpressCheckout = ({
	useDivider = true,
	dividerText = "or",
	applePayBraintreeClient,
	applePaySupport,
	braintree3dSecureClient,
	sessionData,
	payAndVerify,
	googlePayBraintreeClient,
	googlePayClient,
	googlePaySupport,
	braintreePaypalClient,
	processingPayment,
	googlePayExpressClient,
}: ExpressCheckoutProps & {
	processingPayment?: boolean;
	useDivider?: boolean;
	dividerText?: string;
	sessionData: Session;
	payAndVerify: (
		nonce: string,
		paymentMethod: string,
		sessionData: Session
	) => Promise<void>;
}) => {
	const [paymentWindowOpen, setPaymentWindowOpen] = useState(false);

	const expressGoogleEnable =
		sessionData.acceptedPayments.includes("EXPRESS GOOGLE PAY");
	const expressAppleEnable =
		sessionData.acceptedPayments.includes("EXPRESS APPLE PAY");
	const expressPaypalEnable =
		sessionData.acceptedPayments.includes("EXPRESS PAYPAL");

	const expressEnable =
		expressAppleEnable || expressGoogleEnable || expressPaypalEnable;

	const loading =
		googlePayBraintreeClient === null &&
		braintreePaypalClient === null &&
		applePayBraintreeClient === null;

	const isProcessingPayment =
		(processingPayment !== undefined && processingPayment) ||
		paymentWindowOpen;

	if (sessionData.costs.total === 0 || !expressEnable) {
		return <></>;
	}

	const expressClose = () => {
		setPaymentWindowOpen(false);
	};
	return (
		<Box display="flex" gap="1em" flexDirection="column">
			{useDivider && !loading && (
				<Divider style={{ marginTop: "1em" }}>{dividerText}</Divider>
			)}

			{!loading && paymentWindowOpen && !processingPayment && (
				<Box>
					<ProcessingPaymentMessage />
				</Box>
			)}
			{processingPayment && (
				<Box>
					<Backdrop open={true} sx={{ zIndex: 2 }} />
					<ProcessingPaymentMessage />
				</Box>
			)}

			{!isProcessingPayment &&
				expressAppleEnable &&
				applePayBraintreeClient &&
				applePaySupport &&
				braintree3dSecureClient &&
				(sessionData.collectDeliveryDetails ? (
					<ApplePayExpress
						type="CHECKOUT"
						sessionData={sessionData}
						applePayBraintreeClient={applePayBraintreeClient}
						braintree3dSecureClient={braintree3dSecureClient}
						onNonceReceived={payAndVerify}
						onButtonClick={() => {
							setPaymentWindowOpen(true);
						}}
						onPaymentWindowClose={expressClose}
					/>
				) : (
					<ApplePayButton
						type="CHECKOUT"
						sessionData={sessionData}
						applePayBraintreeClient={applePayBraintreeClient}
						braintree3dSecureClient={braintree3dSecureClient}
						onNonceReceived={payAndVerify}
					/>
				))}

			{!isProcessingPayment &&
				expressGoogleEnable &&
				googlePaySupport &&
				googlePayClient &&
				googlePayExpressClient &&
				googlePayBraintreeClient &&
				braintree3dSecureClient &&
				(sessionData.collectDeliveryDetails ? (
					<ExpressGooglePayButton
						sessionData={sessionData}
						onNonceReceived={payAndVerify}
						braintree3dSecureClient={braintree3dSecureClient}
						googlePayBraintreeClient={googlePayBraintreeClient}
						googlePayClient={googlePayExpressClient}
					/>
				) : (
					<GooglePayButton
						type="checkout"
						sessionData={sessionData}
						onNonceReceived={payAndVerify}
						braintree3dSecureClient={braintree3dSecureClient}
						googlePayBraintreeClient={googlePayBraintreeClient}
						googlePayClient={googlePayClient}
						onButtonClick={() => {
							setPaymentWindowOpen(true);
						}}
						onPaymentWindowClose={expressClose}
					/>
				))}

			{!isProcessingPayment &&
				expressPaypalEnable &&
				braintreePaypalClient && (
					<ExpressPaypal
						sessionData={sessionData}
						onNonceReceived={payAndVerify}
						braintreePaypalClient={braintreePaypalClient}
					/>
				)}
		</Box>
	);
};

export default ExpressCheckout;
