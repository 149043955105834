import { Close } from "@mui/icons-material";
import {
	Alert,
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useVerifiyVoucher } from "../../../Hooks/Mutations/useVerifiyVoucher";
import { useDialogWidth } from "../../../Hooks/useDialogWidth";
import { useErrorMessage } from "../../../Hooks/useErrorMessage";
import { handleAxiosError } from "../../../Utilities/backendRequests";
import GreenButton from "../../GreenButton";

const VoucherDialog = ({
	open,
	setOpen,
}: {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const { setErrors, errorMessage, clearErrors } = useErrorMessage({
		marginBottom: "1em",
	});
	const [successMessage, setSuccessMessage] = useState("");

	const queryClient = useQueryClient();
	const dialogWidth = useDialogWidth();

	const mutateVoucher = useVerifiyVoucher(queryClient);

	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm<{ voucherCode: string }>({ reValidateMode: "onSubmit" });
	const submitForm = handleSubmit(async (data) => {
		clearErrors();
		setSuccessMessage("");
		try {
			await mutateVoucher(data.voucherCode, {
				...handleAxiosError(setErrors),
				onSuccess: () => {
					setSuccessMessage("Your discount has been activated");
				},
			});
		} catch (error) {
			//The above code will handle the axios error just making sure it stays
			//open for the error message
			setOpen(true);
		}
	});

	return (
		<Dialog open={open} {...dialogWidth}>
			<Box
				display="flex"
				alignItems="center"
				margin="0 16px"
				justifyContent="space-between"
			>
				<Typography fontWeight={600}>Add Gift Card or Voucher</Typography>
				<DialogActions>
					<IconButton
						onClick={() => {
							setSuccessMessage("");
							setOpen(false);
						}}
					>
						<Close fontSize="medium" />
					</IconButton>
				</DialogActions>
			</Box>
			<Divider />

			<DialogContent>
				{errorMessage}
				{successMessage !== "" && (
					<Alert variant="filled" severity="success" style={{ marginBottom: "1em" }}>
						{successMessage}
					</Alert>
				)}
				<form onSubmit={submitForm}>
					<Box style={{ marginBottom: "1em" }}>
						<TextField
							{...register("voucherCode", { required: true })}
							size="medium"
							label="Code"
							fullWidth={true}
							error={!!errors.voucherCode}
							helperText={!!errors.voucherCode ? "Please enter a voucher code" : ""}
						></TextField>
					</Box>

					<GreenButton
						type="submit"
						text="Apply Code"
						loading={isSubmitting}
						disabled={isSubmitting}
					/>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default VoucherDialog;
