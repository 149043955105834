import axios from "axios";
import { Address, LoqateAddress } from "../Types/AddressTypes";
import { Session } from "../Types/SessionType";

const key = "ZF28-ET85-KR52-PW23";

export async function loquateAddressListFromPostcode(postcode: string) {
	const response = await fetch(
		`https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws?Key=${key}&Text=${postcode}`
	);

	const json = await response.json();

	if (json.Items[0].Type === "Address") {
		return json.Items;
	}

	const id = json.Items[0].Id;

	const drilledResponse = await fetch(
		`https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws?Key=${key}&Text=${postcode}&Container=${id}`
	);

	const drilledJson = await drilledResponse.json();
	return drilledJson.Items;
}

export async function loquateAddressListFromAddressId(addressId: string) {
	const response = await fetch(
		`https://api.addressy.com/Capture/Interactive/Retrieve/v1.20/json3.ws?Key=${key}&Id=${addressId}`
	);
	const json = await response.json();

	return json.Items;
}

export function translateLoqateAddress(
	address: LoqateAddress,
	fullName: string
) {
	return {
		fullName: fullName,
		postcode: address.PostalCode,
		addressLine1: address.Line1,
		addressLine2: address.Line2,
		addressLine3: address.Line3,
		countryCode: address.CountryIso2,
		organisation: address.Company,
		city: address.City,
		id: null,
	};
}

export async function addAddressToSession(address: Address) {
	const { data } = await axios.post(
		process.env.REACT_APP_BACKEND_API_URL + "/session/address",
		{
			address: address,
			setAsSelectedDeliveryAddress: true,
			setAsSelectedBillingAddress: true,
		}
	);

	return data.data.session;
}

export async function updateSessionAddress(
	address: Address,
	addressIndex: number
) {
	const { data } = await axios.put(
		process.env.REACT_APP_BACKEND_API_URL + "/session/address/" + addressIndex,
		{
			address: address,
		}
	);

	return data.data.session;
}

export async function updateSelectedAddress(
	addressType: "billing" | "delivery",
	index: number
): Promise<Session> {
	let url = process.env.REACT_APP_BACKEND_API_URL + "/session/address/delivery";
	if (addressType === "billing") {
		url = process.env.REACT_APP_BACKEND_API_URL + "/session/address/billing";
	}

	const { data } = await axios.put(url, { addressId: index });

	return data.data.session;
}

export function getSelectedAddress(
	sessionData: Session
): Address | null {
	if (sessionData.selectedDeliveryAddress === null) {
		return null;
	}

	if (sessionData.addresses[sessionData.selectedDeliveryAddress] === undefined) {
		return null;
	}

	return sessionData.addresses[sessionData.selectedDeliveryAddress];
}

export const splitName = (fullName: string) => {
	const parts = fullName.split(" ");
	const surname = parts.pop();

	return {
		surname: surname ?? "",
		forenames: parts.join(" "),
	};
};
