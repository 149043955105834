import { captureException } from "@sentry/browser";
import axios from "axios";
import { useEffect, useState } from "react";

export const useBraintreeClientToken = (sessionToken: string) => {
  const [clientToken, setClientToken] = useState({
    fetching: true,
    token: "",
  });

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_BACKEND_API_URL +
          "/session/clientToken?token=" +
          sessionToken
      )
      .then((data) => {
        setClientToken({ fetching: false, token: data.data.data.clientToken });
      })
      .catch(() => {
        console.log('Retrying client token...')
        setTimeout(() => {
          axios
            .get(
              process.env.REACT_APP_BACKEND_API_URL +
                "/session/clientToken?token=" +
                sessionToken
            )
            .then((data) => {
              setClientToken({
                fetching: false,
                token: data.data.data.clientToken,
              });
            })
            .catch((error) => {
              captureException(error);
              console.log(
                "There was an error getting the braintree client token",
                error
              );
            });
        }, 500);
      });
  }, [sessionToken]);

  return clientToken;
};
