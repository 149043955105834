import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { Delivery } from "../Types/DeliveryTypes";

export const getDeliveryOptionDate = (deliveryOption: Delivery) => {
	dayjs.extend(advancedFormat);
	const earliestDate = dayjs(deliveryOption.earliestArrival);
	const latestDate = dayjs(deliveryOption.latestArrival);
	const useDateString = deliveryOption.dateString !== '';

	return useDateString
		? deliveryOption.dateString
		: earliestDate.format("ddd Do MMM") === latestDate.format("ddd Do MMM")
			? earliestDate.format("ddd Do MMM")
			: earliestDate.format("ddd Do MMM") +
				" - " +
				latestDate.format("ddd Do MMM");
};
