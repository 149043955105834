import { DialogProps } from "@mui/material";
import { useIsMobile } from "./useIsMobile";

export const useDialogWidth = (): Pick<
	DialogProps,
	"maxWidth" | "PaperProps" | "fullScreen"
> => {
	const isMobile = useIsMobile();
	return isMobile
		? { fullScreen: true }
		: { maxWidth: false, PaperProps: { style: { width: 640 } } };
};
