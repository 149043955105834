import { QueryClient, useMutation } from "react-query";
import { onErrorFunction, onSuccessFunction } from "../../Types/MutationTypes";
import { updateSelectedDeliveryOption } from "../../Utilities/backendRequests";

export const useUpdateDeliveryOption = (
	queryClient: QueryClient,
	onError?: onErrorFunction,
	onSuccess?: onSuccessFunction
) => {
	const updateDeliveryOption = useMutation(updateSelectedDeliveryOption, {
		onSuccess: (data) => {
			queryClient.setQueryData("session", data);
			if (onSuccess) {
				onSuccess(data);
			}
		},
		onError: (data) => {
			if (onError) {
				onError(data);
			}
		},
	});

	return {
		updateDeliveryOption: updateDeliveryOption.mutateAsync,
	};
};
