import { useMemo } from "react";

export default function useCurrencyFormatter() {
	const GBPFormatter = useMemo(
		() =>
			new Intl.NumberFormat("en-US", {
				style: "currency",
				currency: "GBP",
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			}),
		[]
	);
	return GBPFormatter;
}
