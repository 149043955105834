import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useState } from "react";
import { Session } from "../Types/SessionType";

export const useGiftMessage = (sessionData: Session) => {
	const [includeGiftMessage, setIncludeGiftMessage] = useState(
		sessionData.giftMessage !== ""
	);

	const [giftMessage, setGiftMessage] = useState(sessionData.giftMessage);

	const giftMessageComponent = sessionData.allowGiftMessage ? (
		<Box display="flex" flexDirection="column">
			<FormControlLabel
				onChange={(_, checked) => {
					setIncludeGiftMessage(checked);
				}}
				checked={includeGiftMessage}
				control={<Checkbox />}
				label="Add gift message"
			/>

			{includeGiftMessage && (
				<TextField
					label="Free Gift Note"
					value={giftMessage}
					multiline
					onChange={(event) => {
						setGiftMessage(event.target.value);
					}}
					helperText="Note printed on the packing slip"
				/>
			)}
		</Box>
	) : (
		<></>
	);

	const getGiftMessage = (): string => {
		if (!includeGiftMessage) {
			return "";
		}

		return giftMessage;
	};

	return { getGiftMessage, giftMessageComponent };
};
