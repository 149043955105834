import braintree from "braintree-web";
import { useEffect, useRef } from "react";
import { useQueryClient } from "react-query";
import { useUpdateDeliveryOption } from "../../../Hooks/Mutations/useUpdateDeliveryOption";
import { Session } from "../../../Types/SessionType";
import {
	addNewAddress,
	log,
	updateUserAsGuest,
} from "../../../Utilities/backendRequests";
import {
	convertPaypalAddressToBackendFormat,
	sessionShippingMethodsToPaypal,
	updatePaypalPrice,
} from "../../../Utilities/paypalFunctions";
import { captureException } from "@sentry/core";

interface PayPalButtonProps {
	braintreePaypalClient: braintree.PayPalCheckout;
	sessionData: Session;
	onNonceReceived: (
		nonce: string,
		paymentMethod: string,
		sessionData: Session
	) => Promise<void>;
	expressCheckout?: {
		shippingMethods: paypal.ShippingOption[];
		onPaymentDataReceived: (
			data: paypal.AuthorizationResponse
		) => Promise<Session>;
		onDeliveryOptionUpdate: (data: any) => Promise<Session>;
	};
	onButtonClick?: () => void;
	onPaymentWindowClose?: () => void;
}

const PayPalButton = ({
	braintreePaypalClient,
	sessionData,
	expressCheckout,
	onNonceReceived,
	onButtonClick,
	onPaymentWindowClose,
}: PayPalButtonProps) => {
	const paypalButtonRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		let isMounted = true;

		async function loadPaypal(
			braintreePaypalClient: braintree.PayPalCheckout
		) {
			if (
				paypalButtonRef.current &&
				paypalButtonRef.current.innerHTML !== ""
			) {
				return;
			}

			const address =
				sessionData.addresses[sessionData.selectedDeliveryAddress!];

			/** 'paypal' refers to a UMD global error, vscode recommends importing the paypal from
			 * paypal-checkout-component. Do not import this. Braintree adds in a paypal
			 * global when loading the paypal sdk
			 */
			// @ts-ignore: TODO remove no check
			const paypalButtonRenderer = paypal.Buttons({
				style: {
					height: 40,
				},
				// @ts-ignore: TODO remove no check
				fundingSource: paypal.FUNDING.PAYPAL,

				onShippingChange: async function (data: any) {
					if (expressCheckout) {
						const updatedSession =
							await expressCheckout.onDeliveryOptionUpdate(data);

						await updatePaypalPrice(
							data.paymentToken,
							updatedSession.costs.total
						);
					}
				},
				createOrder: function () {
					let paymentData: braintree.PayPalCheckoutCreatePaymentOptions =
						{
							// @ts-ignore: TODO remove no check
							flow: "checkout",
							amount: sessionData.costs.total
								.toFixed(2)
								.toString(),
							currency: "GBP",
							// @ts-ignore: TODO remove no check
							intent: "authorize",
							enableShippingAddress: false,
							shippingAddressEditable: false,
						};

					if (expressCheckout) {
						paymentData.shippingAddressEditable = true;
						paymentData.enableShippingAddress = true;

						if (sessionData.collectDeliveryDetails) {
							paymentData.shippingOptions =
								expressCheckout.shippingMethods;
						}

						paymentData.amount =
							sessionData.costs.subTotal.toFixed(2);
					} else {
						paymentData.shippingAddressOverride = {
							recipientName: address.fullName,
							line1: address.addressLine1,
							line2: address.addressLine2,
							city: address.city,
							countryCode: address.countryCode,
							postalCode: address.postcode,
							state: "",
						};
					}

					return braintreePaypalClient.createPayment(paymentData);
				},

				onApprove: async function (data, _) {
					const tokenisedResponse: paypal.AuthorizationResponse =
						await braintreePaypalClient.tokenizePayment(data);

					(async function () {
						let updatedSession = sessionData;
						if (expressCheckout) {
							updatedSession =
								await expressCheckout.onPaymentDataReceived(
									tokenisedResponse
								);
						}

						if (
							tokenisedResponse.details.shippingAddress !==
							undefined
						) {
							updatedSession = await addNewAddress(
								convertPaypalAddressToBackendFormat(
									tokenisedResponse.details.shippingAddress
								),
								true,
								false
							);
						}

						await onNonceReceived(
							tokenisedResponse.nonce,
							"paypal",
							updatedSession
						);
					})();

					return tokenisedResponse;
				},

				onCancel: function (data) {
					console.log(
						"PayPal payment cancelled",
						JSON.stringify(data)
					);
					if (onPaymentWindowClose) {
						onPaymentWindowClose();
					}
				},

				onClick: function () {
					if (expressCheckout) {
						log("paypal-express", "click", {});
					} else {
						log("paypal", "click", {});
					}

					if (onButtonClick) {
						onButtonClick();
					}
				},

				onError: function (err) {
					console.error("PayPal error", err);
				},
			});

			if (isMounted) {
				try {
					await paypalButtonRenderer.render("#paypal-button");
				} catch (error) {
					if (error instanceof Error && error.message !== "Detected container element removed from DOM") {
						log("paypal-error", error.message, {});
						captureException(error);
					}
				}
			}
		}

		loadPaypal(braintreePaypalClient);

		return () => {
			isMounted = false;
		};
	});

	return <div id="paypal-button" ref={paypalButtonRef} />;
};

export const ExpressPaypal = ({ ...props }: PayPalButtonProps) => {
	const queryClient = useQueryClient();
	const { updateDeliveryOption } = useUpdateDeliveryOption(queryClient);
	// @ts-ignore: TODO complaining about the type
	const shippingMethods: paypal.ShippingOption[] =
		sessionShippingMethodsToPaypal(props.sessionData);

	return (
		<PayPalButton
			{...props}
			expressCheckout={{
				shippingMethods,
				onPaymentDataReceived: async (data) => {
					let newSession = await addNewAddress(
						convertPaypalAddressToBackendFormat(
							data.details.shippingAddress!
						),
						false,
						true
					);

					if (newSession.user.type === "guest") {
						return await updateUserAsGuest(
							data?.details?.email ?? ""
						);
					}
					return newSession;
				},
				onDeliveryOptionUpdate: async (data) => {
					let newSession = props.sessionData;
					if (data?.selected_shipping_option?.id) {
						newSession = await updateDeliveryOption(
							parseInt(data.selected_shipping_option.id)
						);
					}

					return newSession;
				},
			}}
		/>
	);
};

export default PayPalButton;
