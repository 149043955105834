import { ThreeDSecureVerifyOptions } from "braintree-web/three-d-secure";
import { Address } from "../Types/AddressTypes";
import { splitName } from "./addressFunctions";
import { getIpFor3ds } from "./backendRequests";

export function removeNonAsciiCharacters(stringToAmend: string) {
	return stringToAmend.replace("/[^\x00-\x7F]/g", "");
}

export const formatGoogleAddress = (
	address: google.payments.api.Address
): Address => {
	return {
		fullName: address.name ?? "",
		addressLine1: address.address1 ?? "",
		addressLine2: address.address2,
		city: address.locality,
		postcode: address.postalCode,
		countryCode: address.countryCode,
	};
};

export const formatApplePayAddress = (
	address: ApplePayJS.ApplePayPaymentContact
): Address => {
	return {
		fullName: address.givenName + " " + address.familyName,
		addressLine1: address.addressLines![0] ?? "",
		addressLine2: address.addressLines![1] ?? "",
		city: address.locality ?? "",
		postcode: address.postalCode ?? "",
		countryCode: address.countryCode ?? "",
	};
};

export const braintreeCardTypeToImage = (cardType: string): string => {
	let image = "";
	switch (cardType) {
		case "MasterCard":
			image = "images/Mastercard.svg";
			break;
		case "Visa":
			image = "images/Visa.svg";
			break;
		case "American Express":
			image = "images/Amex.svg";
			break;
		case "Maestro":
			image = "images/Maestro.svg";
			break;
	}

	return image;
};

export const threeDSecureVerify = async (
	amount: number,
	threeDSecureClient: braintree.ThreeDSecure,
	nonce: string,
	bin: string,
	billingAddress: Address,
	shippingAddress: Address,
	phoneNumber: string,
	email: string,
	force3DSecure: boolean = false
) => {
	const name = await splitName(billingAddress.fullName);
	const shippingName = await splitName(shippingAddress.fullName);
	const ipAddress = await getIpFor3ds();

	const parameters: ThreeDSecureVerifyOptions = {
		amount: `${amount}`,
		collectDeviceData: true,
		bin,
		nonce,
		email,
		billingAddress: {
			givenName: name.forenames,
			surname: name.surname,
			streetAddress: billingAddress.addressLine1,
			locality: billingAddress.city,
			postalCode: billingAddress.postcode,
			countryCodeAlpha2: billingAddress.countryCode,
			phoneNumber,
		},
		additionalInformation: {
			shippingPhone: phoneNumber,
			shippingGivenName: shippingName.forenames,
			shippingSurname: shippingName.surname,
			// @ts-ignore: https://braintree.github.io/braintree-web/current/ThreeDSecure.html#~additionalInformation
			// Indicate that region and other info are optional. They are not optional in the types we have.
			shippingAddress: {
				streetAddress: shippingAddress.addressLine1,
				locality: shippingAddress.city,
				postalCode: shippingAddress.postcode,
				countryCodeAlpha2: shippingAddress.countryCode,
			},
			ipAddress: ipAddress,
		},
		// @ts-ignore: Not in braintree type definition ignore for now
		onLookupComplete: function (_: any, next: Function) {
			next();
		},
	};

	if (force3DSecure) {
		parameters.challengeRequested = true;
	}

	return threeDSecureClient.verifyCard(parameters);
};
