import { WarningAmber } from "@mui/icons-material";
import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { captureException } from "@sentry/browser";
import axios from "axios";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useSetUserAsGuestMutation } from "../../Hooks/Mutations/useSetUserAsGuestMutation";
import { setAxiosInterceptors } from "../../Utilities/backendRequests";
import CenterScreenMessage from "../CenterScreenMessage";
import ContinueAsGuestForm from "../Groups/Account/ContinueAsGuestForm";
import Header from "../Header";

const OAuthCallback = () => {
	const [searchParams] = useSearchParams();
	const authorisationCode = searchParams.get("code");
	const [oAuthState, setOAuthState] = useState("loading");

	const queryClient = useQueryClient();
	const { setUserAsGuest } = useSetUserAsGuestMutation(queryClient);
	const [email, setEmail] = useState("");

	const token = localStorage.getItem("token") ?? "";
	setAxiosInterceptors(token);

	useEffect(() => {
		axios
			.get(process.env.REACT_APP_BACKEND_API_URL + "/oauth/callback", {
				params: {
					code: authorisationCode,
				},
			})
			.then(async (data) => {
				localStorage.setItem("accessToken", data.data.data.accessToken);
				await axios.put(process.env.REACT_APP_BACKEND_API_URL + "/session/user/login", {
					accessToken: data.data.data.accessToken,
				});

				window.location.href = process.env.REACT_APP_BASE_URL + "/" + token ?? "https://payment.eden.co.uk";
			})
			.catch((error) => {
				captureException(error);
				setOAuthState("error");
				console.log("There was an OAuth error!", error);
			});
	}, [authorisationCode, token]);

	return (
		<>
			{oAuthState === "loading" && (
				<>
					<Header />
					<CenterScreenMessage icon={<CircularProgress />} message="Securely logging you in ..." />
				</>
			)}

			{oAuthState === "error" && (
				<>
					<Box
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							height: "70vh",
						}}
					>
						<Box
							style={{
								width: "500px",
								display: "flex",
								flexDirection: "column",
								placeContent: "center",
								alignItems: "center",
								textAlign: "center",
							}}
						>
							<WarningAmber sx={{ color: "orange", fontSize: "3em" }} />
							<Typography fontWeight={600} variant="h6">
								Sorry, we were unable to log you in.
							</Typography>
							<Typography fontWeight={400} variant="body2" style={{ marginBottom: "2em" }}>
								Please use the below form to continue as guest.
							</Typography>
							<ContinueAsGuestForm
								email={email}
								setEmail={setEmail}
								onSubmit={async (email: string) => {
									await setUserAsGuest({ email: email });
									window.location.href =
										process.env.REACT_APP_BASE_URL ?? "https://payment.eden.co.uk";
								}}
								style={{
									display: "flex",
									flexDirection: "column",
									width: "90%",
									gap: "1em",
								}}
							/>
						</Box>
					</Box>
				</>
			)}
		</>
	);
};

export default OAuthCallback;
