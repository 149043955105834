// TODO remove the above no checkout, paymentRequest has problems understanding types
import { Box } from "@mui/material";
import { useQueryClient } from "react-query";
import { useAddAddressMutation } from "../../../Hooks/Mutations/useAddAddressMutation";
import { useUpdateDeliveryOption } from "../../../Hooks/Mutations/useUpdateDeliveryOption";
import { Session } from "../../../Types/SessionType";
import {
	convertAppleAddressToBackendFormat,
	sessionShippingMethodsToAppleFormat,
} from "../../../Utilities/applePayFunctions";
import {
	addNewAddress,
	getDeliveryOptions,
	log,
	updateUserAsGuest,
} from "../../../Utilities/backendRequests";
import "./applePay.css";

interface ApplePayExpressCheckout {
	shippingMethods: ApplePayJS.ApplePayShippingMethod[];
	onPaymentDataReceived: (
		paymentData: ApplePayJS.ApplePayPayment
	) => Promise<Session>;
	onDeliveryOptionUpdate: (
		event: ApplePayJS.ApplePayShippingMethodSelectedEvent,
		applePaySession: ApplePaySession
	) => void;
	onDeliveryAddressUpdate: (
		event: ApplePayJS.ApplePayShippingContactSelectedEvent,
		applePaySession: ApplePaySession
	) => void;
}

interface ApplePayProps {
	type: "BUY" | "CHECKOUT";
	applePayBraintreeClient: braintree.ApplePay;
	braintree3dSecureClient: braintree.ThreeDSecure;
	sessionData: Session;
	onNonceReceived: (
		nonce: string,
		paymentMethod: string,
		sessionData: Session
	) => Promise<void>;
	expressCheckout?: ApplePayExpressCheckout;
	onButtonClick?: () => void;
	onPaymentWindowClose?: () => void;
}

function applePayOnClick(
	applePayBraintreeClient: braintree.ApplePay,
	sessionData: Session,
	onNonceReceived: (
		nonce: string,
		paymentMethod: string,
		sessionData: Session
	) => Promise<void>,
	expressCheckout?: ApplePayExpressCheckout,
	onButtonClick?: () => void,
	onPaymentWindowClose?: () => void
) {
	if (onButtonClick) {
		onButtonClick();
	}

	if (expressCheckout) {
		log("apple-pay-express", "click", {});
	} else {
		log("apple-pay", "click", {});
	}

	const paymentRequest = applePayBraintreeClient.createPaymentRequest({
		total: {
			label: "Eden Ecommerce LTD",
			amount: sessionData.costs.total.toString(),
		},
		requiredBillingContactFields: ["postalAddress", "name"],
		countryCode: "GB",
	});

	if (expressCheckout) {
		paymentRequest.shippingMethods = expressCheckout.shippingMethods;
		paymentRequest.requiredShippingContactFields = [
			"postalAddress",
			"name",
			"email",
		];
		paymentRequest.requiredBillingContactFields = [
			"postalAddress",
			"name",
			"email",
		];
	}

	// @ts-ignore: TODO - Type do not match. Do not know why
	const applePaySession = new ApplePaySession(3, paymentRequest);

	if (expressCheckout) {
		applePaySession.onshippingmethodselected = (event) =>
			expressCheckout.onDeliveryOptionUpdate(event, applePaySession);

		applePaySession.onshippingcontactselected = (event) => {
			expressCheckout.onDeliveryAddressUpdate(event, applePaySession);
		};
	}

	applePaySession.onvalidatemerchant = function (event) {
		applePayBraintreeClient
			.performValidation({
				validationURL: event.validationURL,
				displayName: "Eden Ecommerce LTD",
			})
			.then(function (merchantSession) {
				applePaySession.completeMerchantValidation(merchantSession);
			})
			.catch(function () {
				if (onPaymentWindowClose) {
					onPaymentWindowClose();
				}
				applePaySession.abort();
			});
	};

	applePaySession.oncancel = () => {
		if (onPaymentWindowClose) {
			onPaymentWindowClose();
		}
	};

	applePaySession.onpaymentauthorized = function (authorisedEvent) {
		applePayBraintreeClient
			.tokenize({
				token: authorisedEvent.payment.token,
			})
			.then(async function (payload) {
				applePaySession.completePayment(ApplePaySession.STATUS_SUCCESS);

				let updatedSessionData = sessionData;

				if (expressCheckout) {
					updatedSessionData =
						await expressCheckout.onPaymentDataReceived(
							authorisedEvent.payment
						);
				}

				const billingInfo = authorisedEvent.payment.billingContact;
				updatedSessionData = await addNewAddress(
					convertAppleAddressToBackendFormat(billingInfo),
					true,
					false
				);

				await onNonceReceived(
					payload.nonce,
					"apple pay",
					updatedSessionData
				);
			})
			.catch(function () {
				if (onPaymentWindowClose) {
					onPaymentWindowClose();
				}
				applePaySession.completePayment(ApplePaySession.STATUS_FAILURE);
			});
	};
	applePaySession.begin();
}

const ApplePayButton = ({
	type,
	applePayBraintreeClient,
	sessionData,
	onNonceReceived,
	expressCheckout,
	onButtonClick,
	onPaymentWindowClose,
}: ApplePayProps) => {
	let applePayButtonType = "";

	switch (type) {
		case "BUY":
			applePayButtonType = "apple-pay-button-buy";
			break;
		case "CHECKOUT":
			applePayButtonType = "apple-pay-button-checkout";
			break;
	}

	return (
		<Box
			style={{ width: "100%", height: 36.5 }}
			className={
				"apple-pay-button apple-pay-button-black " + applePayButtonType
			}
			onClick={() =>
				applePayOnClick(
					applePayBraintreeClient,
					sessionData,
					onNonceReceived,
					expressCheckout,
					onButtonClick,
					onPaymentWindowClose
				)
			}
		></Box>
	);
};

export default ApplePayButton;

export const ApplePayExpress = ({
	...props
}: Omit<ApplePayProps, "expressCheckout">) => {
	const sessionData = props.sessionData;
	const queryClient = useQueryClient();
	const { addAddress } = useAddAddressMutation(queryClient);
	const { updateDeliveryOption } = useUpdateDeliveryOption(queryClient);

	const shippingMethods = sessionShippingMethodsToAppleFormat(sessionData);

	//Move the select shipping method to the front of the array so it is
	//displayed on the apple pay screen as the selected option
	const selectedShippingMethod =
		shippingMethods[sessionData.selectedDeliveryOption];
	shippingMethods[sessionData.selectedDeliveryOption] = shippingMethods[0];
	shippingMethods[0] = selectedShippingMethod;

	return (
		<ApplePayButton
			{...props}
			expressCheckout={{
				shippingMethods,
				onPaymentDataReceived: async (paymentData) => {
					const deliveryInfo = paymentData.shippingContact;

					const session = await addAddress({
						address:
							convertAppleAddressToBackendFormat(deliveryInfo),
						setAsBillingAddress: false,
						setAsDeliveryAddress: true,
					});

					if (session.user.type === "guest") {
						return await updateUserAsGuest(
							paymentData.shippingContact?.emailAddress ?? ""
						);
					}

					return session;
				},
				onDeliveryOptionUpdate: async (event, applePaySession) => {
					updateDeliveryOption(
						parseInt(event.shippingMethod.identifier),
						{
							onSuccess: (data) => {
								applePaySession.completeShippingMethodSelection(
									{
										newTotal: {
											amount: data.costs.total.toString(),
											label: "Eden Ecommerce LTD",
										},
									}
								);
							},
							onError: () => {},
						}
					);
				},
				onDeliveryAddressUpdate: async (event, applePaySession) => {
					try {
						const session = await getDeliveryOptions(
							event.shippingContact.countryCode ?? "",
							event.shippingContact.postalCode ?? ""
						);

						applePaySession.completeShippingContactSelection({
							newTotal: {
								amount: session.costs.total.toString(),
								label: "Eden Ecommerce LTD",
							},
							newShippingMethods:
								sessionShippingMethodsToAppleFormat(session),
						});
					} catch (_) {
						applePaySession.abort();
					}
				},
			}}
		/>
	);
};
