import { AccountBalanceWalletTwoTone } from "@mui/icons-material";
import {
	Backdrop,
	Box,
	Button,
	Divider,
	LinearProgress,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useErrorMessage } from "../../../Hooks/useErrorMessage";
import { useOnNonceReceived } from "../../../Hooks/useOnNonceReceived";
import { useSavedCards } from "../../../Hooks/useSavedCards";
import { ApplePayBraintreeHookType } from "../../../Types/ApplePayBraintreeTypes";
import { PaypalBraintreeHookType } from "../../../Types/BraintreePaypalTypes";
import { CheckoutComponentProps } from "../../../Types/CheckoutType";
import { GooglePayBraintreeHookType } from "../../../Types/GooglePayBraintreeTypes";
import { log } from "../../../Utilities/backendRequests";
import AccordionRow from "../../AccordionRow";
import AddButton from "../../AddButton";
import GreenButton from "../../GreenButton";
import DonationAmountSelect from "../Donations/DonationAmountSelect";
import PaymentInfo from "./PaymentInfo";
import ApplePayButton from "./ApplePayButton";
import Card from "./Card";
import GooglePayButton from "./GooglePayButton";
import PaymentRadioSelect from "./PaymentRadioSelect";
import PayPalButton, { ExpressPaypal } from "./PaypalButton";
import ProcessingPaymentMessage from "./ProcessingPaymentMessage";
import SavedCards from "./SavedCards";
import { useExperiment } from "../../../Hooks/useExperiment";

export type PaymentMethodStateType =
	| "list"
	| "saved"
	| "add"
	| "cheque"
	| "account";

type PaymentMethodProps = {
	braintreeClient: braintree.Client | null;
	braintree3dSecureClient: braintree.ThreeDSecure | null;
	deviceData: string | null;
} & CheckoutComponentProps &
	ApplePayBraintreeHookType &
	GooglePayBraintreeHookType &
	PaypalBraintreeHookType;

function PaymentMethod({
	appState,
	sessionData,
	stateId,
	deviceData,
	braintree3dSecureClient,
	braintreeClient,
	applePaySupport,
	applePayBraintreeClient,
	googlePayBraintreeClient,
	googlePayClient,
	googlePaySupport,
	braintreePaypalClient,
}: PaymentMethodProps) {
	const variant = useExperiment(
		"KFJAZn-bSGGFPWM_QLeKRQ",
		sessionData.settings.transactionSource
	);

	const {
		isFetching: fetchingSavedCards,
		data: savedCards
	} = useSavedCards();

	const { setErrors, errorMessage, clearErrors } = useErrorMessage({
		margin: "1em 0",
	});

	const [paymentMethodState, setPaymentMethodState] =
		useState<PaymentMethodStateType>("list");

	const [takingPayment, setTakingPayment] = useState(false);
	const [updatingDonations, setUpdatingDonations] = useState(false);

	useEffect(() => {
		const newState =
			sessionData.user.type === "registered" &&
			(savedCards?.savedCards.length ?? 0) !== 0 &&
			!sessionData.user.hasCredit
				? "saved"
				: "list";
		setPaymentMethodState(newState);
	}, [
		fetchingSavedCards,
		savedCards?.savedCards.length,
		sessionData.user.type,
		sessionData.user.hasCredit,
	]);

	const { payAndVerify } = useOnNonceReceived(
		setErrors,
		setTakingPayment,
		deviceData
	);
	const fetchingData =
		fetchingSavedCards ||
		braintreeClient === null ||
		braintree3dSecureClient === null ||
		updatingDonations ||
		(paymentMethodState === "saved" && fetchingSavedCards);

	const currentAppState = appState[stateId].state;

	const fetchingState = fetchingData && currentAppState === "CURRENT";

	const noPaymentState =
		!fetchingData &&
		currentAppState === "CURRENT" &&
		sessionData.costs.total === 0 &&
		!takingPayment;

	const baseState =
		!fetchingData &&
		currentAppState === "CURRENT" &&
		sessionData.costs.total !== 0;

	const listPaymentState = baseState && paymentMethodState === "list";
	const addNewPaymentState = baseState && paymentMethodState === "add";
	const chequePaymentState = baseState && paymentMethodState === "cheque";
	const accountPaymentState = baseState && paymentMethodState === "account";
	const savedCardState = baseState && paymentMethodState === "saved";

	const takingPaymentState =
		!fetchingData && currentAppState === "CURRENT" && takingPayment;

	return (
		<>
			{takingPaymentState && <Backdrop open={takingPaymentState} sx={{ zIndex: 2 }} />}
			{currentAppState === "CURRENT" && (
				<>
					<Box padding="1em" data-testid="donation-form">
						<DonationAmountSelect
							donationName={sessionData.donation.name}
							selectedDonationOption={
								sessionData.selectedDonationOption
							}
							donationOptions={sessionData.donationOptions}
							allowVariableOption={
								sessionData.donation.allowVariableOption
							}
							giftAid={sessionData.donation.giftAid}
							sessionData={sessionData}
							setUpdatingDonations={setUpdatingDonations}
						/>
					</Box>

					<Divider />
				</>
			)}

			<AccordionRow
				state={appState[stateId].state}
				icon={<AccountBalanceWalletTwoTone fontSize="medium" />}
				typography="Payment Method"
				expandable={false}
				expandOnClick={() => {}}
			>
				{errorMessage}
				{fetchingState && (
					<LinearProgress style={{ marginTop: "1em" }} />
				)}

				{takingPaymentState && <ProcessingPaymentMessage />}

				{noPaymentState && (
					<GreenButton
						text="Complete Order"
						style={{ marginTop: "1em" }}
						onClick={() => {
							log("commit-order", "click", {
								payment: "not-required",
								session: sessionData,
							});
							payAndVerify(
								"no-payment",
								"no-payment",
								sessionData
							);
						}}
					/>
				)}

				<Box style={takingPaymentState ? { display: "none" } : {}}>
					{listPaymentState && (
						<>
							<PaymentRadioSelect
								inSplitTest={variant !== undefined}
								getButtonToShow={(paymentMethod) => {
									switch (paymentMethod) {
										case "account":
											return (
												<GreenButton
													text="Continue"
													onClick={() => {
														clearErrors();
														setPaymentMethodState(
															"account"
														);
													}}
												/>
											);
										case "cheque":
											return (
												<GreenButton
													data-testid="payCheque"
													text="Continue"
													onClick={() => {
														clearErrors();
														setPaymentMethodState(
															"cheque"
														);
													}}
												/>
											);
										case "card":
											return (
												<GreenButton
													data-testid="payCard"
													text="Continue"
													onClick={() => {
														clearErrors();
														setPaymentMethodState(
															"add"
														);
													}}
												/>
											);
										case "paypal":
											if (
												braintreePaypalClient === null
											) {
												return (
													<GreenButton
														text="Payment Method Unavailable"
														disabled={true}
													/>
												);
											}

											if (
												!sessionData.collectDeliveryDetails
											) {
												return (
													<ExpressPaypal
														braintreePaypalClient={
															braintreePaypalClient
														}
														sessionData={
															sessionData
														}
														onNonceReceived={
															payAndVerify
														}
													/>
												);
											}

											return (
												<PayPalButton
													braintreePaypalClient={
														braintreePaypalClient
													}
													sessionData={sessionData}
													onNonceReceived={
														payAndVerify
													}
													onButtonClick={() => {
														setTakingPayment(true);
													}}
													onPaymentWindowClose={() => {
														setTakingPayment(false);
													}}
												/>
											);

										case "apple-pay":
											if (
												applePayBraintreeClient === null
											) {
												return (
													<GreenButton
														text="Payment Method Unavailable"
														disabled={true}
													/>
												);
											}
											return (
												<ApplePayButton
													sessionData={sessionData}
													braintree3dSecureClient={
														braintree3dSecureClient
													}
													type="BUY"
													applePayBraintreeClient={
														applePayBraintreeClient
													}
													onNonceReceived={
														payAndVerify
													}
													onButtonClick={() => {
														setTakingPayment(true);
													}}
													onPaymentWindowClose={() => {
														setTakingPayment(false);
													}}
												/>
											);
										case "google-pay":
											if (
												googlePayClient === null ||
												googlePayBraintreeClient ===
													null
											) {
												return (
													<GreenButton
														text="Payment Method Unavailable"
														disabled={true}
													/>
												);
											}

											return (
												<GooglePayButton
													type="buy"
													braintree3dSecureClient={
														braintree3dSecureClient
													}
													sessionData={sessionData}
													googlePayClient={
														googlePayClient
													}
													googlePayBraintreeClient={
														googlePayBraintreeClient
													}
													onNonceReceived={
														payAndVerify
													}
													onButtonClick={() => {
														setTakingPayment(true);
													}}
													onPaymentWindowClose={() => {
														setTakingPayment(false);
													}}
												/>
											);
									}
								}}
								showApplePay={
									applePaySupport &&
									sessionData.acceptedPayments.includes(
										"APPLE PAY"
									) &&
									applePayBraintreeClient !== null
								}
								showGooglePay={
									googlePaySupport &&
									sessionData.acceptedPayments.includes(
										"GOOGLE PAY"
									) &&
									googlePayClient !== null &&
									googlePayBraintreeClient !== null
								}
								showCard={sessionData.acceptedPayments.includes(
									"CARD"
								)}
								showPayPal={
									sessionData.acceptedPayments.includes(
										"PAYPAL"
									) && braintreePaypalClient !== null
								}
								showCheque={sessionData.acceptedPayments.includes(
									"CHEQUE"
								)}
								showAccount={sessionData.user.hasCredit}
							/>

							{sessionData.user.type === "registered" &&
								(savedCards?.savedCards.length ?? 0) !== 0 && (
									<Button
										style={{
											textTransform: "uppercase",
											color: "#2E7D32",
											backgroundColor: "transparent",
											width: "100%",
											marginTop: "1em",
											fontWeight: 600,
										}}
										onClick={() => {
											setPaymentMethodState("saved");
										}}
									>
										<Typography
											style={{
												width: "100%",
											}}
											fontWeight={600}
										>
											Back to saved payments
										</Typography>
									</Button>
								)}
						</>
					)}

					{addNewPaymentState && (
						<Box style={{ margin: "16px 16px 0" }}>
							<Card
								braintreeClient={braintreeClient}
								braintree3dSecureClient={
									braintree3dSecureClient
								}
								sessionData={sessionData}
								setErrors={setErrors}
								setTakingPayment={setTakingPayment}
								deviceData={deviceData}
							/>
							<Button
								style={{
									textTransform: "uppercase",
									color: "#2E7D32",
									backgroundColor: "transparent",
									width: "100%",
									marginTop: "1em",
									fontWeight: 600,
								}}
								onClick={() => {
									setPaymentMethodState("list");
									clearErrors();
								}}
							>
								Choose another way to pay
							</Button>
						</Box>
					)}

					{(chequePaymentState || accountPaymentState) && (
						<Box style={{ margin: "16px 16px 0" }}>
							<PaymentInfo
								sessionData={sessionData}
								deviceData={deviceData}
								setTakingPayment={setTakingPayment}
								setErrors={setErrors}
								paymentType={paymentMethodState}
							/>
							<Button
								style={{
									textTransform: "uppercase",
									color: "#2E7D32",
									backgroundColor: "transparent",
									width: "100%",
									marginTop: "1em",
									fontWeight: 600,
								}}
								onClick={() => {
									clearErrors();
									setPaymentMethodState("list");
								}}
							>
								Choose another way to pay
							</Button>
						</Box>
					)}

					{savedCardState && (
						<>
							<SavedCards
								savedCards={savedCards!.savedCards}
								braintree3dSecureClient={
									braintree3dSecureClient
								}
								sessionData={sessionData}
								defaultCard={savedCards!.default}
								deviceData={deviceData}
								setErrors={setErrors}
								setTakingPayment={setTakingPayment}
							>
								<AddButton
									testId="addNewPaymentMethod"
									style={{ marginBottom: "1em" }}
									text="Add New Payment Method"
									onClick={() => {
										setPaymentMethodState("list");
									}}
								/>
							</SavedCards>
						</>
					)}
				</Box>
			</AccordionRow>
		</>
	);
}

export default PaymentMethod;
