import { Address } from "../Types/AddressTypes";
import { Session } from "../Types/SessionType";
import { getDeliveryOptionDate } from "./deliveryOptionFunctions";

export const convertGoogleAddressToBackendFormat = (
	address: google.payments.api.Address
): Address => {
	return {
		addressLine1: address.address1 ?? "",
		addressLine2: address.address2 ?? "",
		addressLine3: address.address3 ?? "",
		city: address.locality,
		countryCode: address.countryCode,
		fullName: address.name ?? "",
		postcode: address.postalCode,
		organisation: "",
	};
};

export const sessionShippingMethodsToGoogleFormat = (
	sessionData: Session,
	formatter: Intl.NumberFormat
): google.payments.api.SelectionOption[] => {
	return sessionData.deliveryOptions.map((deliveryOption, index) => {
		return {
			id: index.toString(),
			label:
				formatter.format(deliveryOption.cost) +
				" | " +
				deliveryOption.description,
			description: getDeliveryOptionDate(deliveryOption),
		};
	});
};
