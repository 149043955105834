import braintree from "braintree-web";
import { useEffect, useState } from "react";
import { ApplePayBraintreeHookType } from "../Types/ApplePayBraintreeTypes";
export const useApplePayBraintree = (
	braintreeClient: braintree.Client | null
) => {
	const [applePayBraintree, setApplePayBraintree] =
		useState<ApplePayBraintreeHookType>({
			applePayBraintreeClient: null,
			applePayHookFetching: true,
			applePaySupport: false,
		});

	useEffect(() => {
		async function getApplePayInfo(braintreeClient: braintree.Client) {
			const applePayBraintreeClient = await braintree.applePay.create({
				client: braintreeClient,
			});

			const applePaySupport =
				(window as any).ApplePaySession &&
				ApplePaySession.supportsVersion(3) &&
				ApplePaySession.canMakePayments();

			setApplePayBraintree({
				applePayBraintreeClient,
				applePayHookFetching: false,
				applePaySupport,
			});
		}

		if (null !== braintreeClient) {
			getApplePayInfo(braintreeClient);
		}
	}, [braintreeClient]);
	return applePayBraintree;
};
