import { QueryClient, useMutation } from "react-query";
import { onErrorFunction, onSuccessFunction } from "../../Types/MutationTypes";
import { verifyVoucher } from "../../Utilities/backendRequests";

export const useVerifiyVoucher = (
	queryClient: QueryClient,
	onError?: onErrorFunction,
	onSuccess?: onSuccessFunction
) => {
	return useMutation(verifyVoucher, {
		onSuccess: (data) => {
			queryClient.setQueryData("session", data);
			if (onSuccess) {
				onSuccess(data);
			}
		},
		onError: (data) => {
			if (onError) {
				onError(data);
			}
		},
	}).mutateAsync;
};
