import { Typography } from "@mui/material";

const EmailRegisteredNotice = () => {
	const containerStyle = {
		padding: "1em",
		backgroundColor: "#eeeeee",
		borderRadius: "15px",
		margin: "0.8em 0 0 0",
	};

	return (
		<div style={containerStyle}>
			<strong>This email is registered.</strong>
			<p style={{ fontSize: "0.9em" }}>
				You can login to your account or continue as Guest.
			</p>

			<Typography ml={"0.5em"} fontWeight={"600"}>
				{" "}
				LOGIN
			</Typography>
		</div>
	);
};

export default EmailRegisteredNotice;
