import { useEffect, useRef } from "react";
import { UseMutateAsyncFunction, useQueryClient } from "react-query";
import { useAddAddressMutation } from "../../../Hooks/Mutations/useAddAddressMutation";
import { useUpdateDeliveryOption } from "../../../Hooks/Mutations/useUpdateDeliveryOption";
import useCurrencyFormatter from "../../../Hooks/useCurrencyFormatter";
import { Country, Session } from "../../../Types/SessionType";
import {
	addNewAddress,
	log,
	updateUserAsGuest,
} from "../../../Utilities/backendRequests";
import {
	formatGoogleAddress,
	threeDSecureVerify,
} from "../../../Utilities/braintreeFunctions";
import {
	convertGoogleAddressToBackendFormat,
	sessionShippingMethodsToGoogleFormat,
} from "../../../Utilities/googlePayFuntions";

interface GooglePayButtonProps {
	googlePayClient: google.payments.api.PaymentsClient;
	googlePayBraintreeClient: braintree.GooglePayment;
	braintree3dSecureClient: braintree.ThreeDSecure;
	sessionData: Session;
	onNonceReceived: (
		nonce: string,
		paymentMethod: string,
		sessionData: Session
	) => Promise<void>;
	type: google.payments.api.ButtonType;
	expressCheckout?: {
		shippingMethods: google.payments.api.ShippingOptionParameters;
		onDeliveryOptionUpdate: UseMutateAsyncFunction<
			Session,
			unknown,
			number,
			unknown
		>;
		onPaymentDataReceived: (
			paymentData: google.payments.api.PaymentData
		) => Promise<Session>;
	};
	onButtonClick?: () => void;
	onPaymentWindowClose?: () => void;
}

const GooglePayButton = ({
	googlePayClient,
	googlePayBraintreeClient,
	braintree3dSecureClient,
	sessionData,
	type,
	onNonceReceived,
	expressCheckout,
	onButtonClick,
	onPaymentWindowClose,
}: GooglePayButtonProps) => {
	const googlePayButtonRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		let isMounted = true;

		if (googlePayButtonRef.current?.innerHTML !== "") {
			return;
		}

		const button = googlePayClient.createButton({
			buttonColor: "default",
			buttonType: type,
			buttonSizeMode: "fill",
			onClick: async () => {
				try {
					if (onButtonClick) {
						onButtonClick();
					}

					if (expressCheckout) {
						log("google-pay-express", "click", {});
					} else {
						log("google-pay", "click", {});
					}

					const paymentDataRequest =
						await googlePayBraintreeClient.createPaymentDataRequest(
							{
								transactionInfo: {
									currencyCode: "GBP",
									totalPriceStatus: "FINAL",
									totalPrice:
										sessionData.costs.total.toString(),
								},
							}
						);

					const cardPaymentMethod =
						paymentDataRequest.allowedPaymentMethods[0];
					cardPaymentMethod.parameters.billingAddressRequired = true;
					cardPaymentMethod.parameters.billingAddressParameters = {
						format: "FULL",
						phoneNumberRequired: true,
					};
					paymentDataRequest.emailRequired = true;

					if (expressCheckout) {
						paymentDataRequest.callbackIntents = [
							"SHIPPING_OPTION",
							"SHIPPING_ADDRESS",
							// "PAYMENT_AUTHORIZATION",
						];
						paymentDataRequest.shippingAddressRequired = true;
						paymentDataRequest.shippingOptionParameters =
							expressCheckout.shippingMethods;
						paymentDataRequest.shippingOptionRequired = true;
						paymentDataRequest.shippingAddressParameters = {
							allowedCountryCodes:
								sessionData.acceptedDeliveryCountries.map(
									(country: Country) => {
										return country.code;
									}
								),
						};
					}

					const paymentData = await googlePayClient.loadPaymentData(
						paymentDataRequest
					);

					const parsedPaymentData =
						await googlePayBraintreeClient.parseResponse(
							paymentData
						);

					const shippingData = expressCheckout ?
						formatGoogleAddress(
							paymentData.shippingAddress!
						) :
						sessionData.addresses[
							sessionData.selectedDeliveryAddress ?? 0
						];

					let googlePayNonce = parsedPaymentData.nonce;

					if (!parsedPaymentData.details.isNetworkTokenized) {
						const threeDSecurePayload = await threeDSecureVerify(
							sessionData.costs.total,
							braintree3dSecureClient,
							parsedPaymentData.nonce,
							parsedPaymentData.details.bin,
							formatGoogleAddress(
								paymentData.paymentMethodData.info?.billingAddress!
							),
							shippingData,
							sessionData.user.phoneNumber ?? "",
							sessionData.user.email ?? "",
						);
						googlePayNonce = threeDSecurePayload.nonce
					}

					let updatedSessionData = sessionData;

					if (expressCheckout) {
						updatedSessionData =
							await expressCheckout.onPaymentDataReceived(
								paymentData
							);
					}

					const billingInfo =
						paymentData.paymentMethodData.info?.billingAddress!;

					updatedSessionData = await addNewAddress(
						convertGoogleAddressToBackendFormat(billingInfo),
						true,
						false
					);

					await onNonceReceived(
						googlePayNonce,
						"google pay",
						updatedSessionData
					);
				} catch {
					log("google-pay", "close", {});
					if (onPaymentWindowClose) {
						onPaymentWindowClose();
					}
				}
			},
		});

		if (isMounted) {
			googlePayButtonRef.current.appendChild(button);
		}

		return () => {
			isMounted = false;
		};
	}, [
		sessionData.costs.total,
		sessionData,
		googlePayClient,
		onNonceReceived,
		type,
		expressCheckout,
		braintree3dSecureClient,
		googlePayBraintreeClient,
		onButtonClick,
		onPaymentWindowClose,
	]);

	return <div ref={googlePayButtonRef} />;
};

export const ExpressGooglePayButton = ({
	...props
}: Omit<GooglePayButtonProps, "expressCheckout" | "type">) => {
	const formatter = useCurrencyFormatter();
	const queryClient = useQueryClient();
	const { updateDeliveryOption } = useUpdateDeliveryOption(queryClient);
	const { addAddress } = useAddAddressMutation(queryClient);

	const shippingMethods = sessionShippingMethodsToGoogleFormat(
		props.sessionData,
		formatter
	);
	return (
		<GooglePayButton
			{...props}
			type="checkout"
			expressCheckout={{
				shippingMethods: {
					defaultSelectedOptionId:
						props.sessionData.selectedDeliveryOption.toString(),
					shippingOptions: shippingMethods,
				},
				onDeliveryOptionUpdate: updateDeliveryOption,
				onPaymentDataReceived: async (paymentData) => {
					const deliveryInfo = paymentData.shippingAddress!;

					let session = await addAddress({
						address:
							convertGoogleAddressToBackendFormat(deliveryInfo),
						setAsBillingAddress: false,
						setAsDeliveryAddress: true,
					});

					if (session.user.type === "guest") {
						session = await updateUserAsGuest(paymentData.email!);
					}

					return session;
				},
			}}
		/>
	);
};

export default GooglePayButton;
