import { EditTwoTone } from "@mui/icons-material";
import {
	Box,
	FormControlLabel,
	IconButton,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";
import React, { ReactElement } from "react";
import { Address } from "../../../Types/AddressTypes";

interface AddressRadioListProps {
	addresses: Address[];
	isEditable: boolean;
	addressIndex: number | null;
	setAddressIndex: React.Dispatch<React.SetStateAction<number | null>>;
	onEdit: (newSelectedAddressIndex: number) => void;
}

function AddressRadioList({
	addresses,
	addressIndex,
	setAddressIndex,
	isEditable,
	onEdit,
}: AddressRadioListProps): ReactElement {
	return (
		<RadioGroup
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-evenly",
				marginBottom: "1em",
				marginLeft: "1em",
			}}
			value={addressIndex}
			name="delivery-select-group"
			onChange={(event) => setAddressIndex(parseInt(event.target.value))}
		>
			{addresses.map((address, index) => {
				return (
					<Box
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
						key={index + "_box"}
					>
						<FormControlLabel
							style={{ margin: "0.5em, 0", width: "100%" }}
							key={index}
							control={<Radio />}
							value={index}
							label={
								<Box
									style={{
										display: "flex",
										flexDirection: "column",
										margin: "0.5em 0",
									}}
								>
									<Typography fontWeight={400}>
										{address.fullName}
									</Typography>
									<Typography variant="caption">
										{address.addressLine1}
									</Typography>
									<Typography variant="caption">
										{address.organisation !== "" &&
											address.organisation + ", "}
										{address.addressLine2}{" "}
										{address.postcode}
									</Typography>
								</Box>
							}
						/>

						{isEditable && (
							<IconButton
								key={index + "_edit"}
								onClick={() => {
									onEdit(index);
								}}
							>
								<EditTwoTone />
							</IconButton>
						)}
					</Box>
				);
			})}
		</RadioGroup>
	);
}

export default AddressRadioList;
