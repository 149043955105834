import { Box, Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useBillingAddressSelector } from "../../../Hooks/useBillingAddressSelector";
import useCurrencyFormatter from "../../../Hooks/useCurrencyFormatter";
import { useOnNonceReceived } from "../../../Hooks/useOnNonceReceived";
import { Session } from "../../../Types/SessionType";
import { log } from "../../../Utilities/backendRequests";
import GreenButton from "../../GreenButton";
import BillingAddressSelector from "./BillingAddressSelector";

const PaymentInfo = ({
	sessionData,
	deviceData,
	setTakingPayment,
	setErrors,
	paymentType,
}: {
	sessionData: Session;
	deviceData: string | null;
	setTakingPayment: React.Dispatch<React.SetStateAction<boolean>>;
	setErrors: React.Dispatch<React.SetStateAction<string[]>>;
	paymentType: "cheque" | "account";
}) => {
	const queryClient = useQueryClient();
	const formatter = useCurrencyFormatter();
	const [fetching, setFetching] = useState(false);
	const [sendEmail, setSendEmail] = useState(true);
	const [purchaseOrderReference, setPurchaseOrderReference] = useState("");
	const { payAndVerify } = useOnNonceReceived(
		setErrors,
		setTakingPayment,
		deviceData
	);

	const billingSelectorForm = useBillingAddressSelector(sessionData);
	let nonce = "";
	let clickLog = "";

	switch (paymentType) {
		case "account":
			nonce = "account-payment";
			clickLog = "pay-by-account";
			break;
		case "cheque":
			nonce = "cheque-payment";
			clickLog = "pay-by-cheque";
			break;
	}

	return (
		<Box>
			{paymentType === "account" && (
				<TextField
					value={purchaseOrderReference}
					label="Purchase Order Reference"
					helperText="Optional"
					fullWidth
					onChange={(event) => {
						setPurchaseOrderReference(event.target.value);
					}}
					style={{ margin: "0 0 1em 0" }}
				/>
			)}
			{
				paymentType === "cheque" && sessionData.settings.transactionSource === "moto" &&
				<FormGroup>
					<FormControlLabel control={<Checkbox checked={sendEmail} onClick={() => setSendEmail(prev => !prev)} />} label="Send Email" />
				</FormGroup>
			}
			<BillingAddressSelector {...billingSelectorForm.props} />
			<GreenButton
				loading={fetching}
				disabled={fetching}
				text={"Pay " + formatter.format(sessionData.costs.total)}
				data-testid="payPaymentInfo"
				onClick={async () => {
					setFetching(true);
					log(clickLog, "click", {});
					const { trigger } = billingSelectorForm.props.form;
					const valid = await trigger();

					if (!valid) {
						setFetching(false);
						return;
					}

					let session: Session | null =
						await billingSelectorForm.util.updateBillingDetails(
							setErrors
						);

					queryClient.setQueryData("session", session);
					await payAndVerify(
						nonce,
						nonce,
						sessionData,
						purchaseOrderReference,
						false,
						sendEmail
					);
					setFetching(false);
				}}
			/>
		</Box>
	);
};

export default PaymentInfo;
