import { FmdGoodTwoTone } from "@mui/icons-material";
import { ReactElement, useState } from "react";
import { useQueryClient } from "react-query";
import { Address } from "../../../Types/AddressTypes";
import { DeliveryDetailsProps } from "../../../Types/CheckoutType";
import AccordionRow from "../../AccordionRow";
import AddressFormatView from "./AddressFormatView";
import FindMyAddressForm from "./FindMyAddressForm";
import SavedAddressForm from "./SavedAddressForm";
import { useErrorMessage } from "../../../Hooks/useErrorMessage";
import { axiosError } from "../../../Utilities/backendRequests";
import { useUpdateDeliveryDetailsMutation } from "../../../Hooks/Mutations/useUpdateDeliveryDetailsMutation";
import ExpressCheckout from "../Payment/ExpressCheckout";
import { useOnNonceReceived } from "../../../Hooks/useOnNonceReceived";

function DeliveryDetails({
	appState,
	setAppState,
	sessionData,
	stateId,
	expressCheckout,
	deviceData,
}: DeliveryDetailsProps): ReactElement {
	const currentAppState = appState[stateId].state;
	const selectedDeliveryIndex = sessionData.selectedDeliveryAddress;
	const [paymentProcessing, setPaymentProcessing] = useState(false);
	const { errorMessage, setErrors, clearErrors } = useErrorMessage({
		margin: "1em 0",
	});

	const { payAndVerify } = useOnNonceReceived(
		setErrors,
		setPaymentProcessing,
		deviceData
	);

	let selectedAddressData: Address | null = null;
	if (
		selectedDeliveryIndex !== null &&
		sessionData.addresses[selectedDeliveryIndex] !== undefined
	) {
		selectedAddressData = sessionData.addresses[selectedDeliveryIndex];
	}

	const queryClient = useQueryClient();
	const [showState, setShowState] = useState(
		sessionData.addresses.length > 0 ? "saved" : "new"
	);
	const toggleShowState = () => {
		clearErrors();
		showState === "saved" ? setShowState("new") : setShowState("saved");
	};

	const onMutationErrorHandler = (error: any) => {
		axiosError(setErrors, error);
	};

	const { updateDeliveryDetails } = useUpdateDeliveryDetailsMutation(
		queryClient,
		onMutationErrorHandler
	);

	async function onContinue(
		selectedAddressIndex: number,
		giftMessage: string,
		phoneNumber: string
	) {
		clearErrors();

		await updateDeliveryDetails({
			phoneNumber,
			giftMessage,
			selectedAddressIndex,
		});

		setShowState("saved");
		setAppState({ currentState: stateId, type: "SET" });
	}

	function render() {
		if (currentAppState === "CURRENT" || currentAppState === "EDIT") {
			if (showState === "saved") {
				return (
					<SavedAddressForm
						addresses={sessionData.addresses}
						sessionData={sessionData}
						onAddNewAddress={() => {
							toggleShowState();
						}}
						onComplete={onContinue}
					/>
				);
			} else {
				return (
					<FindMyAddressForm
						sessionData={sessionData}
						onBackToAddressBook={toggleShowState}
						onComplete={onContinue}
					/>
				);
			}
		}

		if (currentAppState === "SET" && selectedAddressData !== null) {
			return <AddressFormatView address={selectedAddressData} />;
		}
	}

	return (
		<AccordionRow
			testId="accordionDeliveryDetail"
			state={currentAppState}
			icon={<FmdGoodTwoTone fontSize="medium"></FmdGoodTwoTone>}
			typography={"Delivery Details"}
			expandable={
				currentAppState !== "CURRENT" &&
				currentAppState !== "EDIT" &&
				currentAppState !== "FUTURE"
			}
			expandOnClick={() =>
				setAppState({ currentState: stateId, type: "EDIT" })
			}
		>
			{errorMessage}
			<>
				{!paymentProcessing && render()}
				{expressCheckout &&
					sessionData.user.type === "registered" &&
					(currentAppState === "CURRENT" ||
						currentAppState === "EDIT") && (
						<ExpressCheckout
							{...expressCheckout}
							payAndVerify={payAndVerify}
							sessionData={sessionData}
							processingPayment={paymentProcessing}
							useDivider={!paymentProcessing}
						/>
					)}
			</>
		</AccordionRow>
	);
}

export default DeliveryDetails;
