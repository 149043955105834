import {
	Box,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";
import { ReactElement } from "react";
import { LoqateAddressLimited } from "../../../Types/AddressTypes";

interface AddressLimitedRadioListProps {
	addresses: LoqateAddressLimited[];
	selectedAddress: string | null;
	onAddressChange: (selectedAddressId: string) => void;
}

function AddressLimitedRadioList({
	addresses,
	selectedAddress,
	onAddressChange,
}: AddressLimitedRadioListProps): ReactElement {
	return (
		<Box
			style={{ overflowY: "auto" }}
			padding="0 1em"
			data-testid="findAddressRadioList"
		>
			<RadioGroup
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-evenly",
					marginBottom: "1em",
					marginLeft: "1em",
					gap: "1em",
				}}
				value={selectedAddress}
				name="delivery-address-select-group"
				onChange={(event) => onAddressChange(event.target.value)}
			>
				{addresses.map((address) => {
					return (
						<FormControlLabel
							key={address.Id}
							control={<Radio />}
							value={address.Id}
							label={
								<Box margin="0 1em">
									<Typography fontWeight={400}>
										{address.Text}
									</Typography>
									<Typography variant="caption">
										{address.Description}
									</Typography>
								</Box>
							}
						></FormControlLabel>
					);
				})}
			</RadioGroup>
		</Box>
	);
}

export default AddressLimitedRadioList;
