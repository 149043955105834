import { Box, Typography } from "@mui/material";
import { Session } from "../../../Types/SessionType";
import useCurrencyFormatter from "../../../Hooks/useCurrencyFormatter";

const VoucherSetSand = ({ sessionData }: { sessionData: Session }) => {
	const formatter = useCurrencyFormatter();

	const containerStyle = {
		margin: "1em 0",
		padding: "1em",
		borderRadius: "10px",
		backgroundColor: "#f8f8f3",
	};

	const textContainerStyle = {
		display: "flex",
		justifyContent: "space-between",
	};

	return (
		<Box style={containerStyle}>
			{sessionData.giftCards.totalAmount > 0 && (
				<Box style={textContainerStyle}>
					<Typography>Your Gift Card balance</Typography>
					<Typography fontWeight={600}>
						{formatter.format(sessionData.giftCards.totalAmount)}
					</Typography>
				</Box>
			)}
		</Box>
	);
};
export default VoucherSetSand;
