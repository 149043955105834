import { Box } from "@mui/system";
import { ReactElement, useReducer } from "react";
import "../checkout-container.css";
import { ApplePayBraintreeHookType } from "../Types/ApplePayBraintreeTypes";
import { PaypalBraintreeHookType } from "../Types/BraintreePaypalTypes";
import { BraintreeHookType } from "../Types/BraintreeTypes";
import {
	CheckoutAction,
	checkoutIds,
	CheckoutReducerState,
} from "../Types/CheckoutType";
import { GooglePayBraintreeHookType } from "../Types/GooglePayBraintreeTypes";
import { Session } from "../Types/SessionType";
import Account from "./Groups/Account/Account";
import DeliveryDetails from "./Groups/DeliveryDetails/DeliveryDetails";
import DeliveryOptions from "./Groups/DeliveryOptions/DeliveryOptions";
import PaymentMethod from "./Groups/Payment/PaymentMethod";
import Vouchers from "./Groups/Vouchers/Vouchers";

interface CheckoutContainerProps {
	data: Session;
	applePayHook: ApplePayBraintreeHookType;
	googlePayHook: GooglePayBraintreeHookType;
	paypalHook: PaypalBraintreeHookType;
	braintreeHook: BraintreeHookType;
}

//TODO come abck and see if we need this, this calculates state so that we can figure out where we should be on refresh or start
function calculateInitialState(data: Session) {
	let initialState: CheckoutReducerState = {
		ACCOUNT: {
			id: "ACCOUNT",
			state: "CURRENT",
			nextState: data.collectDeliveryDetails
				? "DELIVERY-DETAIL"
				: "PAYMENT-METHOD",
		},
		"DELIVERY-DETAIL": {
			id: "DELIVERY-DETAIL",
			state: "FUTURE",
			nextState: "DELIVERY-OPTION",
		},
		"DELIVERY-OPTION": {
			id: "DELIVERY-OPTION",
			state: "FUTURE",
			nextState: "PAYMENT-METHOD",
		},
		DONATION: {
			id: "DONATION",
			state: "FUTURE",
			nextState: "VOUCHER",
		},
		VOUCHER: {
			id: "VOUCHER",
			state: "FUTURE",
			nextState: "PAYMENT-METHOD",
		},
		"PAYMENT-METHOD": {
			id: "PAYMENT-METHOD",
			state: "FUTURE",
			nextState: null,
		},
	};

	if (
		data.user.type === "registered" ||
		(data.user.type === "guest" && data.user.email !== null)
	) {
		initialState.ACCOUNT.state = "SET";
		initialState[initialState.ACCOUNT.nextState!].state = "CURRENT";
	}

	if (
		initialState.ACCOUNT.state === "SET" &&
		data.selectedDeliveryAddress !== null
	) {
		initialState["DELIVERY-DETAIL"].state = "SET";
		initialState[initialState["DELIVERY-DETAIL"].nextState!].state =
			"CURRENT";
	}

	return initialState;
}

function checkoutStateReducer(
	state: CheckoutReducerState,
	action: CheckoutAction
) {
	let newState = { ...state };

	switch (action.type) {
		case "EDIT":
			checkoutIds.forEach((stateId) => {
				if (
					newState[stateId].state === "CURRENT" ||
					newState[stateId].state === "EDIT"
				) {
					newState[stateId].state = "SET";
				}
			});
			break;
		case "SET":
			let nextState = newState[action.currentState].nextState;

			if (nextState !== null) {
				newState[nextState].state = "CURRENT";
			}
			break;
	}

	newState[action.currentState].state = action.type;
	return newState;
}

function CheckoutContainer({
	data,
	applePayHook,
	braintreeHook: { deviceData, braintree3dSecureClient, braintreeClient },
	googlePayHook,
	paypalHook,
}: CheckoutContainerProps): ReactElement {
	const [appState, setAppState] = useReducer(
		checkoutStateReducer,
		calculateInitialState(data)
	);

	if (
		data.user.type === "registered" &&
		appState["ACCOUNT"].state !== "SET"
	) {
		setAppState({ currentState: "ACCOUNT", type: "SET" });
		return <></>;
	}

	const expressCheckoutProps = {
		expressCheckout: {
			applePayBraintreeClient: applePayHook.applePayBraintreeClient,
			applePaySupport: applePayHook.applePaySupport,
			braintree3dSecureClient,
			braintreeClient,
			googlePaySupport: googlePayHook.googlePaySupport,
			googlePayBraintreeClient: googlePayHook.googlePayBraintreeClient,
			googlePayClient: googlePayHook.googlePayClient,
			googlePayExpressClient: googlePayHook.googlePayExpressClient,
			braintreePaypalClient: paypalHook.braintreePaypalClient,
		},
	};

	return (
		<Box className="checkout-container">
			<Account
				user={data.user}
				sessionData={data}
				setAppState={setAppState}
				appState={appState}
				stateId="ACCOUNT"
				{...expressCheckoutProps}
				deviceData={deviceData}
			/>
			{data.collectDeliveryDetails && (
				<DeliveryDetails
					sessionData={data}
					setAppState={setAppState}
					appState={appState}
					stateId="DELIVERY-DETAIL"
					{...expressCheckoutProps}
					deviceData={deviceData}
				/>
			)}
			{data.collectDeliveryDetails && (
				<DeliveryOptions
					deliveryOptions={data.deliveryOptions}
					selectedDeliveryOption={data.selectedDeliveryOption}
					sessionData={data}
					setAppState={setAppState}
					appState={appState}
					stateId="DELIVERY-OPTION"
				/>
			)}
			<Vouchers
				sessionData={data}
				setAppState={setAppState}
				appState={appState}
				stateId="VOUCHER"
			/>
			<PaymentMethod
				googlePayExpressClient={googlePayHook.googlePayExpressClient}
				sessionData={data}
				setAppState={setAppState}
				appState={appState}
				deviceData={deviceData}
				stateId="PAYMENT-METHOD"
				braintree3dSecureClient={braintree3dSecureClient}
				braintreeClient={braintreeClient}
				applePayBraintreeClient={applePayHook.applePayBraintreeClient}
				applePaySupport={applePayHook.applePaySupport}
				applePayHookFetching={applePayHook.applePayHookFetching}
				googlePayBraintreeClient={
					googlePayHook.googlePayBraintreeClient
				}
				googlePayClient={googlePayHook.googlePayClient}
				googlePayFetching={googlePayHook.googlePayFetching}
				googlePaySupport={googlePayHook.googlePaySupport}
				braintreePaypalClient={paypalHook.braintreePaypalClient}
				paypalFetching={paypalHook.paypalFetching}
			></PaymentMethod>
		</Box>
	);
}

export default CheckoutContainer;
