import { Box, Typography } from "@mui/material";
import useCurrencyFormatter from "../../../Hooks/useCurrencyFormatter";
import { Session } from "../../../Types/SessionType";

const VoucherSet = ({ sessionData }: { sessionData: Session }) => {
	const formatter = useCurrencyFormatter();

	const containerStyle = { marginLeft: "2.25em" };

	return (
		<Box style={containerStyle}>
			{sessionData.giftCards.totalAmount > 0 && (
				<Typography variant="body2">
					Your Gift Card balance{" "}
					{formatter.format(sessionData.giftCards.totalAmount)}
				</Typography>
			)}
		</Box>
	);
};

export default VoucherSet;
