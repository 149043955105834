import {
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, ReactElement, useEffect } from "react";
import { useController, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import useCurrencyFormatter from "../../../Hooks/useCurrencyFormatter";
import { useErrorMessage } from "../../../Hooks/useErrorMessage";
import { DeliveryOptionsFormProps } from "../../../Types/DeliveryTypes";
import {
	handleAxiosError,
	log,
	updateSelectedDeliveryOption,
} from "../../../Utilities/backendRequests";
import { getDeliveryOptionDate } from "../../../Utilities/deliveryOptionFunctions";
import GreenButton from "../../GreenButton";

function DeliveryOptionsForm({
	deliveryOptions,
	sessionData,
	onUpdateSuccess,
}: DeliveryOptionsFormProps): ReactElement {
	const queryClient = useQueryClient();
	const { clearErrors, errorMessage, setErrors } = useErrorMessage();

	const updateDeliveryOption = useMutation(updateSelectedDeliveryOption, {
		onSuccess: (data) => {
			queryClient.setQueryData("session", data);
			clearErrors();
		},
		...handleAxiosError(setErrors),
	});

	const formatter = useCurrencyFormatter();
	const {
		handleSubmit,
		control,
		formState: { isSubmitting },
	} = useForm<{ deliveryOptionRadio: number }>({
		defaultValues: {
			deliveryOptionRadio: sessionData.selectedDeliveryOption ?? 0,
		},
	});
	const { field } = useController({
		name: "deliveryOptionRadio",
		control: control,
	});

	const submitForm = handleSubmit(async (data) => {
		log("delivery-option-change", "click", { data: data });
		await updateDeliveryOption.mutateAsync(data.deliveryOptionRadio);
	});

	useEffect(() => {
		const ga4Data = sessionData.deliveryOptions.filter(deliveryOption => (deliveryOption.showChristmasTruck));

		if (window.dataLayer) {
			window.dataLayer.push({
				event: "christmas_truck_event",
				ecommerce: {
					shownTruck: ga4Data.length > 0,
				},
			})
		}
	}, [sessionData])

	return (
		<FormControl style={{ padding: "1em 0 1em 0", width: "100%" }}>
			{errorMessage}
			<RadioGroup
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-evenly",
					marginBottom: "1em",
					marginLeft: "1em",
				}}
				name={field.name}
				onChange={(e) => {
					field.onChange(e);
					submitForm()
				}}
				value={field.value}
				data-testid="deliveryOptionsRadioList"
			>
				{deliveryOptions.map((deliveryOption, index) => {
					const cost =
						deliveryOption.cost === 0
							? "Free"
							: formatter.format(deliveryOption.cost);

					const description = getDeliveryOptionDate(deliveryOption);

					const finalDescription = cost + " | " + description;

					return (
						<Fragment key={deliveryOption.service}>
							<FormControlLabel
								style={{ margin: "0.5em, 0, 0.5em, 0" }}
								control={<Radio />}
								value={index}
								label={
									<Box
										style={{
											display: "flex",
											flexDirection: "column",
											margin: "0.5em 0 0.5em 0",
										}}
									>
										<Typography>{deliveryOption.description}</Typography>
										<Typography variant="body2">{finalDescription}</Typography>
										<Typography variant="body2">
											{deliveryOption.extraDetail}
										</Typography>
									</Box>
								}
							></FormControlLabel>

							{deliveryOption.showChristmasTruck && <Box ml="2em" display="flex" gap={0.5} mb={1}>
								<img
									src="/images/ChristmasTruck.svg"
									alt="Christmas delivery truck"
									width={32}
									height={24}
								/>
								<Typography variant="body2" fontSize={14} sx={{ alignSelf: "end" }}>Delivered by Christmas</Typography>
							</Box>}
						</Fragment>
					);
				})}
			</RadioGroup>

			<GreenButton
				loading={isSubmitting}
				disabled={isSubmitting}
				text={"Continue"}
				onClick={() => {
					log("delivery-option-continue", "click", {})
					onUpdateSuccess()
				}}
				data-testid="deliveryOptionsContinue"
			/>
		</FormControl>
	);
}

export default DeliveryOptionsForm;
