import { Box, Typography } from "@mui/material";
import { useIsMobile } from "../Hooks/useIsMobile";
import { PaymentMethodIcon } from "../Types/PaymentTypes";
import { AcceptedPaymentMethod } from "../Types/SessionType";
import PaymentMethodList from "./PaymentMethodList";

const AcceptedPaymentMethodsList = ({
	acceptedPayments,
}: {
	acceptedPayments: AcceptedPaymentMethod[];
}) => {
	const isMobile = useIsMobile();
	let paymentMethods: PaymentMethodIcon[] = [];

	if (
		acceptedPayments.includes("PAYPAL") ||
		acceptedPayments.includes("EXPRESS PAYPAL")
	) {
		paymentMethods.push({
			url: "images/Paypal.svg",
			alt: "Paypal",
		});
	}

	if (
		acceptedPayments.includes("GOOGLE PAY") ||
		acceptedPayments.includes("EXPRESS GOOGLE PAY")
	) {
		paymentMethods.push({
			url: "images/GooglePay.svg",
			alt: "Google Pay",
		});
	}

	if (
		acceptedPayments.includes("APPLE PAY") ||
		acceptedPayments.includes("EXPRESS APPLE PAY")
	) {
		paymentMethods.push({
			url: "images/ApplePay.svg",
			alt: "Apple Pay",
		});
	}

	if (acceptedPayments.includes("CARD")) {
		paymentMethods.push(
			{
				url: "images/Visa.svg",
				alt: "Visa",
			},
			{
				url: "images/Mastercard.svg",
				alt: "Mastercard",
			},
			{
				url: "images/Amex.svg",
				alt: "Amex",
			}
		);
	}

	paymentMethods = paymentMethods.slice(0, 5);
	return (
		<Box padding="1em" style={isMobile ? { background: "white" } : {}}>
			<Typography
				variant="body2"
				fontWeight={400}
				style={{ color: "rgba(0, 0, 0, 0.6)", marginBottom: "0.5em" }}
			>
				Accepted Payment Methods
			</Typography>
			<PaymentMethodList iconUrls={paymentMethods} />
		</Box>
	);
};

export default AcceptedPaymentMethodsList;
