import { Box, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { useIsMobile } from "../../../Hooks/useIsMobile";

interface basketSpaceTextProps {
	rightHandValue: string | number;
	leftHandValue: string;
	bold?: boolean;
	style?: React.CSSProperties;
}

const BasketSpacedText = ({
	rightHandValue,
	leftHandValue,
	bold = false,
	style = {},
}: basketSpaceTextProps): ReactElement => {
	const isMobile = useIsMobile();
	return (
		<Box display="flex" justifyContent="space-between" style={style}>
			<Typography style={{ fontWeight: bold ? 600 : undefined, maxWidth: !isMobile ? 200 : undefined  }}>
				{leftHandValue}
			</Typography>
			<Typography style={bold ? { fontWeight: 600 } : {}}>
				{rightHandValue}
			</Typography>
		</Box>
	);
};

export default BasketSpacedText;
