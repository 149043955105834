import { useEffect, useState } from "react";

declare global {
  interface Window {
    dataLayer: any;
    google_optimize: any;
  }
}

export const useExperiment = (
  experimentId: string,
  transactionSource?: string
) => {
  const [variant, setVariant] = useState();
  useEffect(() => {
    if (transactionSource !== "moto") {
      (async () => {
        if (window.dataLayer) {
          await window.dataLayer.push({ event: "optimize.activate" });
        }
        const intervalId = setInterval(() => {
          if (window.google_optimize !== undefined) {
            // Set the variant to the state.
            setVariant(window.google_optimize.get(experimentId));
            clearInterval(intervalId);
          }
        }, 100);
      })();
    }
  }, [transactionSource, experimentId]);
  return variant;
};
