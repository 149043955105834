import { Session } from "../Types/SessionType";
import {
	handleAxiosError,
	pay,
	verifySession,
} from "../Utilities/backendRequests";

export const useOnNonceReceived = (
	setErrors: React.Dispatch<React.SetStateAction<string[]>>,
	setFetching: React.Dispatch<React.SetStateAction<boolean>>,
	deviceData: string | null,
	onError?: () => void
) => {
	const payAndVerify = async (
		nonce: string,
		paymentMethod: string,
		sessionData: Session,
		paymentReference: string = "",
		saveCard: boolean = false,
		sendEmail: boolean = true,
	) => {
		try {
			window.onbeforeunload = () => {
				//Probably wont be show due to new browsers but old browsers still support.
				//It's mostly used as a flag at this point.
				return "Order placing is currently in progress, leaving or refreshing the page can cause problems.";
			};
			setErrors([]);
			setFetching(true);
			const valid = await verifySession(paymentMethod);
			if (valid) {
				await pay(
					nonce,
					sessionData,
					deviceData,
					saveCard,
					paymentReference,
					sendEmail
				);
			} else {
				setErrors(["Basket is not valid"]);
			}
		} catch (error) {
			if (onError) {
				onError();
			}

			handleAxiosError(setErrors).onError(error);
			setFetching(false);
		} finally {
			//Remove our event
			window.onbeforeunload = () => {};
		}
	};

	return {
		payAndVerify,
	};
};
