import { Typography } from "@mui/material";
import "./App.css";

function App() {
  
  return <>
    <Typography variant="h3">This is not the page you are looking for...</Typography>
  </>
}

export default App;
