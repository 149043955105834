import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { sleep } from "../../../Utilities/sleep";

const ProcessingPaymentMessage = () => {
	const [messageToDisplay, setMessageToDisplay] = useState('We are verifying your payment details...');

	useEffect(() => {
		(async () => {
			await sleep(3000);
			setMessageToDisplay('Please wait - we are setting up your order ...');
			await sleep(3000);
			setMessageToDisplay('Nearly there, we are taking Payment...');
		})()
	}, []);

	return (
		<>
			<Typography
				textAlign="center"
				style={{ margin: "1em 0" }}
			>
				{messageToDisplay}
			</Typography>

			<Typography
				textAlign="center"
				style={{ margin: "1em 0" }}
			>
				Please do not refresh or exit this page.
			</Typography>

			<Box display="flex" justifyContent="center">
				<CircularProgress color="success" />
			</Box>
		</>
	);
};

export default ProcessingPaymentMessage;
