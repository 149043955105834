import braintree from "braintree-web";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { GooglePayBraintreeHookType } from "../Types/GooglePayBraintreeTypes";
import { getDeliveryOptions } from "../Utilities/backendRequests";
import { sleep } from "../Utilities/sleep";
import { sessionShippingMethodsToGoogleFormat } from "../Utilities/googlePayFuntions";
import { useUpdateDeliveryOption } from "./Mutations/useUpdateDeliveryOption";
import useCurrencyFormatter from "./useCurrencyFormatter";

export const useGooglePayBraintree = (
	braintreeClient: braintree.Client | null
) => {
	const queryClient = useQueryClient();
	const formatter = useCurrencyFormatter();
	const { updateDeliveryOption } = useUpdateDeliveryOption(queryClient);
	const [googlePayBraintree, setGooglePayBraintree] =
		useState<GooglePayBraintreeHookType>({
			googlePayBraintreeClient: null,
			googlePayClient: null,
			googlePayFetching: true,
			googlePaySupport: false,
			googlePayExpressClient: null,
		});

	useEffect(() => {
		async function setUpGooglePayBraintree(
			braintreeClient: braintree.Client
		) {
			const googleEnvironment =
				process.env.REACT_APP_PRODUCTION === "true"
					? "PRODUCTION"
					: "TEST";

			let googlePayClient: null | google.payments.api.PaymentsClient =
				null;

			let googlePayRetryTime = 5000;
			while (googlePayClient === null) {
				try {
					googlePayClient = new google.payments.api.PaymentsClient({
						environment: googleEnvironment,
					});
				} catch (_) {
					await sleep(googlePayRetryTime);
					googlePayRetryTime *= 2;
				}
			}

			const expressClient = new google.payments.api.PaymentsClient({
				environment: googleEnvironment,
				paymentDataCallbacks: {
					onPaymentDataChanged: (intermediatePaymentData) => {
						return new Promise(async function (resolve, reject) {
							let trigger =
								intermediatePaymentData.callbackTrigger;

							if (trigger === "SHIPPING_OPTION") {
								let shippingOption =
									intermediatePaymentData.shippingOptionData;

								try {
									const newSession =
										await updateDeliveryOption(
											parseInt(shippingOption!.id)
										);
									resolve({
										newTransactionInfo: {
											currencyCode: "GBP",
											totalPrice:
												newSession.costs.total.toString(),
											totalPriceStatus: "FINAL",
										},
									});
								} catch (_) {
									reject();
								}
							} else if (trigger === "SHIPPING_ADDRESS") {
								const shippingAddress =
									intermediatePaymentData.shippingAddress;

								try {
									const session = await getDeliveryOptions(
										shippingAddress?.countryCode ?? "",
										shippingAddress?.postalCode ?? ""
									);

									resolve({
										newTransactionInfo: {
											currencyCode: "GBP",
											totalPrice:
												session.costs.total.toString(),
											totalPriceStatus: "FINAL",
										},
										newShippingOptionParameters: {
											shippingOptions:
												sessionShippingMethodsToGoogleFormat(
													session,
													formatter
												),
										},
									});
								} catch (_) {
									reject();
								}
							} else {
								resolve({});
							}
						});
					},
				},
			});

			const googlePayBraintreeClient =
				await braintree.googlePayment.create({
					client: braintreeClient,
					googlePayVersion: 2,
					googleMerchantId: "BCR2DN4TRCD4NTYS",
				});

			const googlePaySupported = await googlePayClient.isReadyToPay({
				apiVersion: 2,
				apiVersionMinor: 0,
				allowedPaymentMethods: (
					await googlePayBraintreeClient.createPaymentDataRequest()
				).allowedPaymentMethods,
			});

			setGooglePayBraintree({
				googlePayBraintreeClient: googlePayBraintreeClient,
				googlePayClient: googlePayClient,
				googlePayFetching: false,
				googlePaySupport: googlePaySupported.result,
				googlePayExpressClient: expressClient,
			});
		}

		if (braintreeClient !== null) {
			setUpGooglePayBraintree(braintreeClient);
		}
	}, [braintreeClient, updateDeliveryOption, formatter]);

	return googlePayBraintree;
};
