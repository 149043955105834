import {
	Box,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import React from "react";
import { Controller, useController } from "react-hook-form";
import { BillingAddressSelectorProps } from "../../../Hooks/useBillingAddressSelector";
import { Country } from "../../../Types/SessionType";

const BillingAddressSelector = ({
	addresses,
	collectDeliveryDetails,
	form,
	acceptedDeliveryCountries,
}: BillingAddressSelectorProps) => {
	const { watch, control, setValue, resetField } = form;

	const { field: billingSameField } = useController({
		name: "billingSame",
		control,
	});

	const { field: selectedAddressField } = useController({
		name: "selectedAddress",
		control,
	});

	const billingAddressOnChange = (
		event: React.ChangeEvent,
		onChange: (...event: any[]) => void
	) => {
		setValue("selectedAddress", -1);
		onChange(event);
	};

	return (
		<div style={{ display: "flex", flexDirection: "column" }}>
			{collectDeliveryDetails && (
				<FormControlLabel
					name={billingSameField.name}
					onChange={billingSameField.onChange}
					control={<Checkbox checked={billingSameField.value} />}
					label="Billing address same as delivery"
				/>
			)}

			{!collectDeliveryDetails && (
				<Typography marginY="1em">Billing Address</Typography>
			)}

			{(!billingSameField.value || !collectDeliveryDetails) && (
				<Box display="flex" gap="1em" flexDirection="column" mt="1em">
					<FormControl fullWidth>
						<InputLabel id="saved-address-label">
							Saved Addresses
						</InputLabel>
						<Select
							labelId="saved-address-label"
							label="Saved Addresses"
							name={selectedAddressField.name}
							onChange={(event) => {
								selectedAddressField.onChange(event);

								const currentSelectedAddress =
									watch("selectedAddress");

								if (currentSelectedAddress === -1) {
									resetField("currentAddress");
								} else {
									setValue(
										"currentAddress",
										addresses[currentSelectedAddress]
									);
								}
							}}
							value={selectedAddressField.value}
						>
							<MenuItem value={-1} key={-1}>
								New Address
							</MenuItem>
							{addresses.map((address, index) => (
								<MenuItem value={index} key={index}>
									{address.fullName +
										", " +
										address.addressLine1 +
										", " +
										address.postcode}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<Controller
						control={control}
						name="currentAddress.fullName"
						render={({ field, fieldState: { error } }) => (
							<TextField
								label="Full Name"
								{...field}
								onChange={(event) =>
									billingAddressOnChange(
										event,
										field.onChange
									)
								}
								error={!!error}
								helperText={error?.message ?? ""}
							/>
						)}
					/>

					<Controller
						control={control}
						name="currentAddress.addressLine1"
						render={({ field, fieldState: { error } }) => (
							<TextField
								label="Address Line 1"
								{...field}
								onChange={(event) =>
									billingAddressOnChange(
										event,
										field.onChange
									)
								}
								error={!!error}
								helperText={error?.message}
							/>
						)}
					/>

					<Controller
						control={control}
						name="currentAddress.addressLine2"
						render={({ field, fieldState: { error } }) => (
							<TextField
								label="Address Line 2"
								{...field}
								onChange={(event) =>
									billingAddressOnChange(
										event,
										field.onChange
									)
								}
								error={!!error}
								helperText={error?.message ?? ""}
							/>
						)}
					/>

					<Controller
						control={control}
						name="currentAddress.addressLine3"
						render={({ field, fieldState: { error } }) => (
							<TextField
								label="Address Line 3"
								{...field}
								onChange={(event) =>
									billingAddressOnChange(
										event,
										field.onChange
									)
								}
								error={!!error}
								helperText={error?.message ?? ""}
							/>
						)}
					/>

					<Controller
						control={control}
						name="currentAddress.city"
						render={({ field, fieldState: { error } }) => (
							<TextField
								label="City"
								{...field}
								onChange={(event) =>
									billingAddressOnChange(
										event,
										field.onChange
									)
								}
								error={!!error}
								helperText={error?.message ?? ""}
							/>
						)}
					/>

					<Controller
						control={control}
						name="currentAddress.postcode"
						render={({ field, fieldState: { error } }) => (
							<TextField
								label="Postcode"
								{...field}
								onChange={(event) =>
									billingAddressOnChange(
										event,
										field.onChange
									)
								}
								error={!!error}
								helperText={error?.message ?? ""}
							/>
						)}
					/>

					<Controller
						control={control}
						name="currentAddress.countryCode"
						render={({ field, fieldState: { error } }) => (
							<FormControl style={{ marginBottom: "1em" }}>
								<InputLabel id="country-select">
									Country
								</InputLabel>
								<Select
									id="country-select"
									label="Country"
									{...field}
								>
									{acceptedDeliveryCountries.map(
										(country: Country) => {
											return (
												<MenuItem
													key={country.code}
													value={country.code}
												>
													{country.country}
												</MenuItem>
											);
										}
									)}
								</Select>
								{!!error && (
									<FormHelperText>
										{error.message}
									</FormHelperText>
								)}
							</FormControl>
						)}
					/>
				</Box>
			)}
		</div>
	);
};

export default BillingAddressSelector;
