import { FormHelperText } from "@mui/material";

interface ErrorMessageProps {
	errorMessage?: string;
	style?: React.CSSProperties;
}
const ErrorMessage = ({ errorMessage, style }: ErrorMessageProps) => {
	return (
		<FormHelperText style={style} error={true}>
			{errorMessage}
		</FormHelperText>
	);
};

export default ErrorMessage;
