import { CardGiftcardTwoTone } from "@mui/icons-material";
import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { CheckoutComponentProps } from "../../../Types/CheckoutType";
import AccordionRow from "../../AccordionRow";
import AddButton from "../../AddButton";
import VoucherDialog from "./VoucherDialog";
import VoucherForm from "./VoucherForm";
import VoucherSet from "./VoucherSet";
import VoucherSetSand from "./VoucherSetSand";

const Vouchers = ({
	appState,
	sessionData,
	setAppState,
	stateId,
}: CheckoutComponentProps) => {
	const currentAppState = appState[stateId].state;
	const [modelOpen, setModelOpen] = useState(false);

	const expandable =
		currentAppState !== "CURRENT" && currentAppState !== "EDIT";

	useEffect(() => {
		if (
			sessionData.giftCards.totalAmount === 0 &&
			(currentAppState === "EDIT" || currentAppState === "CURRENT")
		) {
			setAppState({ currentState: stateId, type: "SET" });
		}
	}, [
		sessionData.giftCards.totalAmount,
		currentAppState,
		setAppState,
		stateId,
	]);
	return (
		<>
			{sessionData.giftCards.totalAmount === 0 &&
			currentAppState !== "CURRENT" ? (
				<>
					<AddButton
						text="Add new gift card or voucher code"
						onClick={() => {
							setModelOpen((prevState) => !prevState);
						}}
					/>
					<Divider />
				</>
			) : (
				<AccordionRow
					state={currentAppState}
					icon={<CardGiftcardTwoTone fontSize="medium" />}
					expandable={expandable}
					expandOnClick={() => {
						setAppState({
							currentState: stateId,
							type: "EDIT",
						});
					}}
					typography="Gift Card or Voucher"
				>
					{(currentAppState === "SET" ||
						currentAppState === "FUTURE") && (
						<VoucherSet sessionData={sessionData} />
					)}

					{(currentAppState === "EDIT" ||
						currentAppState === "CURRENT") && (
						<>
							{sessionData.giftCards.totalAmount > 0 && (
								<VoucherSetSand sessionData={sessionData} />
							)}

							<VoucherForm
								currentStateId={stateId}
								setAppState={setAppState}
								setModelOpen={setModelOpen}
							></VoucherForm>
						</>
					)}
				</AccordionRow>
			)}

			<VoucherDialog open={modelOpen} setOpen={setModelOpen} />
		</>
	);
};

export default Vouchers;
