import { Add } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

const defaultStyle: React.CSSProperties = {
	alignSelf: "flex-start",
	padding: "1em",
	textTransform: "uppercase",
};

const AddButton = ({
	onClick,
	text,
	style = {},
	testId,
}: {
	onClick: () => void;
	text: string;
	style?: React.CSSProperties;
	testId?: string;
}) => {
	return (
		<Button
			fullWidth={true}
			style={{ ...defaultStyle, ...style }}
			onClick={onClick}
			data-testid={testId}
		>
			<Box display="flex" gap="0.75em" marginLeft="1em" width="100%">
				<Add style={{ color: "#1976D2" }} />
				<Typography fontWeight={600}>{text}</Typography>
			</Box>
		</Button>
	);
};

export default AddButton;
