import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, UseFormReturn } from "react-hook-form";
import * as Yup from "yup";
import { Address } from "../Types/AddressTypes";
import { Country, Session } from "../Types/SessionType";
import { updateSelectedAddress } from "../Utilities/addressFunctions";
import { addNewAddress, handleAxiosError } from "../Utilities/backendRequests";

export interface BillingAddressFormType {
	billingSame?: boolean | undefined;
	selectedAddress: number;
	currentAddress: {
			fullName?: string | undefined;
			addressLine1?: string | undefined;
			addressLine2?: string | undefined;
			addressLine3?: string | undefined;
			postcode?: string | undefined;
			organisation?: string | undefined;
			city?: string | undefined;
			countryCode?: string | undefined;
	};
}

export interface BillingAddressSelectorProps {
	addresses: Address[];
	collectDeliveryDetails: boolean;
	acceptedDeliveryCountries: Country[];
	form: UseFormReturn<BillingAddressFormType, any, undefined>;
}

const yupAddressBillSame = Yup.object().shape({
	fullName: Yup.string().required("Full Name is required!"),
	addressLine1: Yup.string().required(
		"Address Line 1 is required!"
	),
	addressLine2: Yup.string(),
	addressLine3: Yup.string(),
	postcode: Yup.string().required("Postcode is required!"),
	organisation: Yup.string(),
	city: Yup.string().required("City is required!"),
	countryCode: Yup.string().required("This is a required field"),
})

const yupAddressBillDifferent = Yup.object().shape({
	fullName: Yup.string(),
	addressLine1: Yup.string(),
	addressLine2: Yup.string(),
	addressLine3: Yup.string(),
	postcode: Yup.string(),
	organisation: Yup.string(),
	city: Yup.string(),
	countryCode: Yup.string(),
})

export const useBillingAddressSelector = (sessionData: Session) => {
	const validationSchema = Yup.object().shape({
		billingSame: Yup.boolean(),
		selectedAddress: Yup.number().required(),
		currentAddress: yupAddressBillDifferent.when("billingSame", {
			is: false,
			then: () => yupAddressBillSame,
		}),
	});

	const form = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			billingSame: sessionData.collectDeliveryDetails,
			selectedAddress: -1,
			currentAddress: {
				addressLine1: "",
				addressLine2: "",
				addressLine3: "",
				city: "",
				countryCode: "GB",
				fullName: "",
				organisation: "",
				postcode: "",
			},
		},
	});

	const updateBillingDetails = async (
		setErrors?: React.Dispatch<React.SetStateAction<string[]>>
	): Promise<Session | null> => {
		const { watch, setValue } = form;

		let session: Session | null = null;

		try {
			if (watch("billingSame")) {
				session = await updateSelectedAddress(
					"billing",
					sessionData.selectedDeliveryAddress!
				);
			} else if (watch("selectedAddress") === -1) {
				session = await addNewAddress(
					watch("currentAddress") as Address,
					true,
					false
				);
			} else {
				session = await updateSelectedAddress(
					"billing",
					watch("selectedAddress")
				);
			}

			setValue("selectedAddress", session.selectedBillingAddress ?? 0);
		} catch (error) {
			if (setErrors) {
				handleAxiosError(setErrors).onError(error);
			}
		}

		return session;
	};

	const props: BillingAddressSelectorProps = {
		addresses: sessionData.addresses,
		collectDeliveryDetails: sessionData.collectDeliveryDetails,
		acceptedDeliveryCountries: sessionData.acceptedDeliveryCountries,
		form,
	};

	return {
		props,
		util: { updateBillingDetails },
	};
};
