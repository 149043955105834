import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactElement } from "react";
import { DeliveryOptionsSetProps } from "../../../Types/DeliveryTypes";
import { getDeliveryOptionDate } from "../../../Utilities/deliveryOptionFunctions";

function DeliveryOptionsSet({
	selectedDeliveryOption,
}: DeliveryOptionsSetProps): ReactElement {
	const description = getDeliveryOptionDate(selectedDeliveryOption);

	const finalDescription =
		selectedDeliveryOption.description + " " + description;
	return (
		<Box display="flex">
			<Grid item container>
				<Grid item xs={12} style={{ marginLeft: "2em" }}>
					<Typography variant="body2">{finalDescription}</Typography>
				</Grid>
			</Grid>
		</Box>
	);
}

export default DeliveryOptionsSet;
