import { QueryClient, useMutation } from "react-query";
import { Address } from "../../Types/AddressTypes";
import { onErrorFunction, onSuccessFunction } from "../../Types/MutationTypes";
import { addNewAddress } from "../../Utilities/backendRequests";
export const useAddAddressMutation = (
	queryClient: QueryClient,
	onError?: onErrorFunction,
	onSuccess?: onSuccessFunction
) => {
	const addAddressToSessionMutation = useMutation(
		({
			address,
			setAsBillingAddress = true,
			setAsDeliveryAddress = true,
		}: {
			address: Address;
			setAsBillingAddress?: boolean;
			setAsDeliveryAddress?: boolean;
		}) => {
			return addNewAddress(address, setAsBillingAddress, setAsDeliveryAddress);
		},
		{
			onSuccess: (data) => {
				queryClient.setQueryData("session", data);
				if (onSuccess) {
					onSuccess(data);
				}
			},
			onError: (data) => {
				if (onError) {
					onError(data);
				}
			},
		}
	);
	return {
		addAddress: addAddressToSessionMutation.mutateAsync,
	};
};
