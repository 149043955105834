import { Alert } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";

const defaultStyle: React.CSSProperties = {};

export const useErrorMessage = (style: React.CSSProperties = {}) => {
	const [errors, setErrors] = useState<string[]>([]);

	const clearErrors = useCallback(() => {
		setErrors([]);
	}, []);

	const errorMessage = useMemo(() => {
		return errors.length === 0 ? (
			<></>
		) : (
			<Alert
				variant="filled"
				severity="error"
				style={{ ...defaultStyle, ...style }}
				data-testid="alertError"
			>
				{errors.join(", ")}
			</Alert>
		);
	}, [errors, style]);

	return { setErrors, clearErrors, errorMessage };
};
