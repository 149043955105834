import { Box, Divider, Typography } from "@mui/material";
import { ReactElement, useState } from "react";
import "../../../basket.css";
import useCurrencyFormatter from "../../../Hooks/useCurrencyFormatter";
import { useIsMobile } from "../../../Hooks/useIsMobile";
import { Costs, Item, Voucher } from "../../../Types/SessionType";
import GreenButton from "../../GreenButton";
import BasketItem from "./BasketItem";
import BasketSpacedText from "./BasketSpacedText";

interface basketProps {
	costs: Costs;
	vouchers: Voucher[];
	items: Item[];
	isGuest: boolean;
	logoutEndpoint: string;
	moto: boolean;
	addressIsSet: boolean;
	hideTotalPreDeliveryOptions: boolean;
}

const Basket = ({
	costs,
	vouchers,
	items,
	isGuest,
	logoutEndpoint,
	moto,
	addressIsSet,
	hideTotalPreDeliveryOptions,
}: basketProps): ReactElement => {
	const formatter = useCurrencyFormatter();
	const isMobile = useIsMobile();
	const [loggingOut, setLoggingOut] = useState(false);

	const containerStyle = isMobile
		? {}
		: {
				width: 320,
				height: "min-content",
				borderRadius: "15px",
		  };

	return (
		<Box
			bgcolor="#f2f2e9"
			className="basket-box"
			style={containerStyle}
			data-testid="basket"
		>
			<Box padding={"1em"}>
				<BasketSpacedText
					leftHandValue="Subtotal"
					rightHandValue={formatter.format(costs.subTotal)}
				/>

				<BasketSpacedText
					leftHandValue="Postage &#38; Packing"
					rightHandValue={formatter.format(costs.deliveryCost)}
					style={{
						marginTop: "1em",
						display: (isGuest === true && addressIsSet === false && hideTotalPreDeliveryOptions === true) ?
							 "none" : "flex"

					}}
				/>

				{costs.donationCost > 0 && (
					<BasketSpacedText
						leftHandValue="Donation"
						rightHandValue={formatter.format(costs.donationCost)}
						style={{ margin: "1em 0" }}
					/>
				)}

				{vouchers.length > 0 &&
					vouchers.map((voucher) => (
						<BasketSpacedText
							key={voucher.code}
							rightHandValue={
								"- " + formatter.format(voucher.value)
							}
							leftHandValue={voucher.description}
							style={{ margin: "1em 0" }}
						/>
					))}

				{costs.preDeductionTotal > 0 && (
					<BasketSpacedText
						leftHandValue="Total"
						rightHandValue={formatter.format(
							costs.preDeductionTotal
						)}
						bold={true}
						style={{ margin: "1em 0" }}
					/>
				)}

				{costs.giftCardCost > 0 && (
					<BasketSpacedText
						leftHandValue="Gift Card Redemption"
						rightHandValue={
							"- " + formatter.format(costs.giftCardCost)
						}
						style={{ margin: "1em 0" }}
					/>
				)}
				<BasketSpacedText
					leftHandValue="Order Total"
					rightHandValue={formatter.format(costs.total)}
					bold={true}
					style={{
						margin: "1em 0",
						display: (isGuest === true && addressIsSet === false && hideTotalPreDeliveryOptions === true) ?
							 "none" : "flex"
					}}
				/>

				<Divider
					light={true}
					sx={{ marginBottom: "1.4em", color: "#0000001F" }}
				></Divider>

				<Typography variant="h6">Order</Typography>
				{items.map((item) => (
					<BasketItem
						key={item.name}
						item={item}
						style={{ marginTop: "1em" }}
					/>
				))}

				{moto && (
					<>
						{!isGuest && (
							<GreenButton
								text="logout and continue as guest"
								loading={loggingOut}
								disabled={loggingOut}
								style={{ marginTop: "1em" }}
								onClick={async () => {
									setLoggingOut(true);
									window.location.href = logoutEndpoint;
								}}
							/>
						)}
					</>
				)}
			</Box>
		</Box>
	);
};

export default Basket;
