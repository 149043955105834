import { Delivery } from "./DeliveryTypes";
import { Session } from "./SessionType";
import { UserType } from "./UserType";

export type CheckoutState = "SET" | "FUTURE" | "CURRENT" | "EDIT";

export const checkoutIds = [
	"ACCOUNT",
	"DELIVERY-OPTION",
	"DELIVERY-DETAIL",
	"VOUCHER",
	"DONATION",
	"PAYMENT-METHOD",
] as const;

export type CheckoutId = typeof checkoutIds[number];

export interface CheckoutComponent {
	state: CheckoutState;
	id: CheckoutId;
	nextState: CheckoutId | null;
}

export type CheckoutAction = { type: CheckoutState; currentState: CheckoutId };

export type AppState = Record<CheckoutId, CheckoutComponent>;

export interface CheckoutComponentProps {
	sessionData: Session;
	setAppState: React.Dispatch<CheckoutAction>;
	appState: AppState;
	stateId: CheckoutId;
}

export type AccountProps = CheckoutComponentProps & {
	user: UserType;
	expressCheckout?: ExpressCheckoutProps;
	deviceData: string | null;
};

export interface ExpressCheckoutProps {
	applePayBraintreeClient?: braintree.ApplePay | null;
	applePaySupport?: boolean;
	braintreeClient?: braintree.Client | null;
	braintree3dSecureClient?: braintree.ThreeDSecure | null;
	googlePayClient?: google.payments.api.PaymentsClient | null;
	googlePayExpressClient?: google.payments.api.PaymentsClient | null;
	googlePayBraintreeClient?: braintree.GooglePayment | null;
	googlePaySupport?: boolean;
	braintreePaypalClient?: braintree.PayPalCheckout | null;
}

export type DeliveryDetailsProps = CheckoutComponentProps & {
	expressCheckout?: ExpressCheckoutProps;
	deviceData: string | null;
};

export type DeliveryOptionProps = CheckoutComponentProps & {
	deliveryOptions: Delivery[];
	selectedDeliveryOption?: number;
};

export type CheckoutReducerState = Record<
	CheckoutId,
	{
		id: CheckoutId;
		state: CheckoutState;
		nextState: CheckoutId | null;
	}
>;
