import { Address } from "../Types/AddressTypes";
import { Session } from "../Types/SessionType";
import { getDeliveryOptionDate } from "./deliveryOptionFunctions";

export const convertAppleAddressToBackendFormat = (
	address: ApplePayJS.ApplePayPaymentContact | undefined
): Address => {
	return {
		addressLine1: address?.addressLines
			? address.addressLines[0] ?? ""
			: "",
		addressLine2: address?.addressLines
			? address.addressLines[1] ?? ""
			: "",
		city: address?.locality ?? "",
		countryCode: address?.countryCode ?? "",
		postcode: address?.postalCode ?? "",
		fullName: (address?.givenName ?? "") + " " + address?.familyName,
	};
};

export const sessionShippingMethodsToAppleFormat = (
	sessionData: Session
): ApplePayJS.ApplePayShippingMethod[] => {
	let deliveryOptions = sessionData.deliveryOptions.map(
		(deliveryOption, index) => {
			return {
				identifier: index.toString(),
				label: deliveryOption.description,
				detail: getDeliveryOptionDate(deliveryOption),
				amount: deliveryOption.cost.toString(),
			};
		}
	);

	let selectedDeliveryOption =
		deliveryOptions[sessionData.selectedDeliveryOption];

	deliveryOptions[sessionData.selectedDeliveryOption] = deliveryOptions[0];

	deliveryOptions[0] = selectedDeliveryOption;
	return deliveryOptions;
};
