import { QueryClient, useMutation } from "react-query";
import { onErrorFunction, onSuccessFunction } from "../../Types/MutationTypes";
import {
	updateUserAsGuest,
} from "../../Utilities/backendRequests";
export const useSetUserAsGuestMutation = (
	queryClient: QueryClient,
	onError?: onErrorFunction,
	onSuccess?: onSuccessFunction
) => {
	const setUserAsGuestMutation = useMutation(
		({ email }: { email: string }) => {
			return updateUserAsGuest(email);
		},
		{
			onSuccess: (data) => {
				queryClient.setQueryData("session", data);
				if (onSuccess) {
					onSuccess(data);
				}
			},
			onError: (data) => {
				if (onError) {
					onError(data);
				}
			},
		}
	);
	return {
		setUserAsGuest: setUserAsGuestMutation.mutateAsync,
	};
};
