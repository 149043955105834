import { Chip, Radio, RadioProps } from "@mui/material";

const chipStyle: React.CSSProperties = {
	fontWeight: 600,
};

const ChipRadio = ({
	label,
	...radioProps
}: {
	label: string;
} & RadioProps) => {
	return (
		<Radio
			{...radioProps}
			checkedIcon={
				<Chip label={label} color="primary" style={chipStyle} />
			}
			icon={<Chip label={label} variant="outlined" style={chipStyle} />}
		/>
	);
};

export default ChipRadio;
