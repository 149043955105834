import { Box, CardMedia, Grid, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import useCurrencyFormatter from "../../../Hooks/useCurrencyFormatter";
import { Item } from "../../../Types/SessionType";

interface basketItemProps {
	item: Item;
	style?: React.CSSProperties;
}

const BasketItem = ({ item, style = {} }: basketItemProps): ReactElement => {
	const formatter = useCurrencyFormatter();
	return (
		<Box display="flex" style={style} data-testid="basketItem">
			<CardMedia
				component="img"
				src={item.imageUrl}
				width="3em"
				height="3em"
				style={{ width: "3em", height: "3em", marginRight: "1em" }}
			/>
			<Grid>
				<Grid item xs={12}>
					<Typography variant="body2">{item.name}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography
						variant="body1"
						style={{ color: "rgba(0, 0, 0, 0.6)" }}
					>
						{item.quantity} x {formatter.format(item.cost)}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};

export default BasketItem;
