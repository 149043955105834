import { DeleteTwoTone } from "@mui/icons-material";
import { Box, InputLabel, Radio } from "@mui/material";

const borderStyle = {
	borderStyle: "solid",
	borderColor: "rgba(0, 0, 0, 0.23)",
	borderWidth: 1,
	borderRadius: "0.25em",
}

const PaymentRadioWithLabel = ({
	image,
	label,
	value,
	includeBorder = true,
	onDeleteCard = undefined,
}: {
	value: string;
	label: string;
	includeBorder?: boolean;
	image?: string;
	onDeleteCard?: (cardId: string, label: string) => void
}) => {

	return <Box display="flex" justifyContent="space-between" marginBottom="1em">
		<Box display="flex" alignItems="center" width="100%">
			<Radio value={value} id={value + "-radio"} />
			<InputLabel
				htmlFor={value + "-radio"}
				style={{ color: "black", cursor: "pointer", width: "100%" }}
			>
				{label}
			</InputLabel>
		</Box>

		<Box ml={1} display="flex" justifyContent="end" alignItems="center" width="100%">
				<Box display="flex" alignItems="center">
				{image !== undefined && (
					<img
						width={48}
						height={36}
						src={image}
						alt={value}
						style={{
							...(includeBorder ? borderStyle : {}),
							display: "inline",
							verticalAlign: "middle",
						}}
					/>
				)}

				{onDeleteCard && 
				<Box padding="0.5em">
					<DeleteTwoTone fontSize="medium" sx={{"&:hover": {cursor: "pointer"}}} onClick={() => onDeleteCard(value, label)}/>
				</Box>
				}
			</Box>
		</Box>
	</Box>;
};

export default PaymentRadioWithLabel;
