import braintree from "braintree-web";
import { useEffect, useState } from "react";
import { BraintreeHookType } from "../Types/BraintreeTypes";
import { sleep } from "../Utilities/sleep";
import { useBraintreeClientToken } from "./useBraintreeClientToken";

export const useBraintreeClient = (sessionToken: string) => {
	const { fetching: fetchingToken, token } =
		useBraintreeClientToken(sessionToken);

	const [clientState, setClientState] = useState<BraintreeHookType>({
		fetching: false,
		braintreeClient: null,
		braintree3dSecureClient: null,
		deviceData: null,
	});

	useEffect(() => {
		async function getClientToken(token: string) {
			const braintreeClient = await braintree.client.create({
				authorization: token,
			});

			const braintree3dSecureClient = await braintree.threeDSecure.create(
				{
					version: 2,
					client: braintreeClient,
				}
			);

			let attempts = 3;
			let dataCollector = null;

			while (attempts > 0) {
				try {
					dataCollector = await braintree.dataCollector.create({
						client: braintreeClient,
					});
					break;
				} catch {
					attempts -= 1;
					sleep(500);
				}
			}

			let deviceData = "";

			if (null !== dataCollector) {
				deviceData = dataCollector.deviceData;
			}

			setClientState({
				fetching: false,
				braintreeClient: braintreeClient,
				braintree3dSecureClient: braintree3dSecureClient,
				deviceData: deviceData,
			});
		}

		if (!fetchingToken) {
			getClientToken(token);
		}
	}, [token, fetchingToken]);

	return clientState;
};
