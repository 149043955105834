import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, RadioGroup } from "@mui/material";
import { ThreeDSecure } from "braintree-web";
import { useMemo, useState } from "react";
import { useBillingAddressSelector } from "../../../Hooks/useBillingAddressSelector";
import useCurrencyFormatter from "../../../Hooks/useCurrencyFormatter";
import { useOnNonceReceived } from "../../../Hooks/useOnNonceReceived";
import { SavedCardRequestType } from "../../../Types/BackendRequestTypes";
import { Session } from "../../../Types/SessionType";
import {
	deleteSavedCard,
	getNonceFromSavedCard,
	handleAxiosError,
	log,
} from "../../../Utilities/backendRequests";
import {
	braintreeCardTypeToImage,
	threeDSecureVerify,
} from "../../../Utilities/braintreeFunctions";
import GreenButton from "../../GreenButton";
import BillingAddressSelector from "./BillingAddressSelector";
import PaymentRadioWithLabel from "./PaymentRadioWithLabel";
import { useQueryClient } from "react-query";

const SavedCards = ({
	savedCards,
	braintree3dSecureClient,
	sessionData,
	defaultCard,
	children,
	setErrors,
	setTakingPayment,
	deviceData,
}: {
	savedCards: SavedCardRequestType[];
	defaultCard: string;
	braintree3dSecureClient: ThreeDSecure;
	sessionData: Session;
	children?: React.ReactNode;
	setErrors: React.Dispatch<React.SetStateAction<string[]>>;
	setTakingPayment: React.Dispatch<React.SetStateAction<boolean>>;
	deviceData: string | null;
}) => {
	const queryClient = useQueryClient();
	const [radio, setRadio] = useState(defaultCard);
	const [cardToRemove, setCardToRemove] = useState<{cardId?: string; cardLabel?: string}>({})
	const [isDeleting, setIsDeleting] = useState(false);
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const formatter = useCurrencyFormatter();
	const { payAndVerify } = useOnNonceReceived(
		setErrors,
		setTakingPayment,
		deviceData
	);
	const [fetching, setFetching] = useState(false);
	const billingSelectorForm = useBillingAddressSelector(sessionData);

	const radioButtons = useMemo(() => savedCards.map((cardDetails) => (
		<PaymentRadioWithLabel
			key={cardDetails.id}
			image={braintreeCardTypeToImage(cardDetails.type)}
			value={cardDetails.id}
			label={"**** **** **** " + cardDetails.last4Digit}
			onDeleteCard={(cardId, cardLabel) => {
				setCardToRemove({cardId, cardLabel});
				setConfirmDialogOpen(true);
			}}
		/>
	)), [savedCards]);

	return (
		<>
			<RadioGroup
				value={radio}
				name="saved-card"
				onChange={(event) => {
					setRadio(event.target.value);
				}}
			>
				{radioButtons}
			</RadioGroup>

			{children !== undefined && children}

			<BillingAddressSelector {...billingSelectorForm.props} />

			<GreenButton
				disabled={fetching}
				loading={fetching}
				data-testid="paySavedCard"
				text={"Pay " + formatter.format(sessionData.costs.total)}
				onClick={async () => {
					log("pay-by-saved-card", "click", {});
					setFetching(true);
					setTakingPayment(true);

					const { trigger } = billingSelectorForm.props.form;
					const valid = await trigger();

					if (!valid) {
						setFetching(false);
						setTakingPayment(false);
						setErrors(["Billing address invalid. Please check billing address and try again."]);
						return;
					}

					if (!radio) {
						setFetching(false);
						setTakingPayment(false);
						setErrors(["Please select a saved card"]);
						return;
					}

					let session: Session | null =
						await billingSelectorForm.util.updateBillingDetails(
							setErrors
						);

					if (session === null) {
						setFetching(false);
						setTakingPayment(false);
						setErrors(["Failed to set billing address"]);
						return;
					}

					const savedCardDetails = await getNonceFromSavedCard(radio);

					try {
						if (!session.settings.threeDSecure) {
							payAndVerify(savedCardDetails.nonce, "card", session);
						} else {
							setTakingPayment(true);
							const threeDPayload = await threeDSecureVerify(
								session.costs.total,
								braintree3dSecureClient,
								savedCardDetails.nonce,
								savedCardDetails.bin,
								session.addresses[
									session.selectedBillingAddress ?? 0
								],
								session.addresses[
									session.selectedDeliveryAddress ?? 0
								],
								session.user.phoneNumber ?? "",
								session.user.email ?? "",
							);
							payAndVerify(threeDPayload.nonce, "card", session);
						}
					} catch (error) {
						setFetching(false);
						setTakingPayment(false);
						handleAxiosError(setErrors).onError(error);
					}
					setFetching(false);
				}}
			/>

		<Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Remove payment method
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the following card: {cardToRemove.cardLabel}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={isDeleting} onClick={() => setConfirmDialogOpen(false)}>No</Button>
          <Button disabled={isDeleting} onClick={async () => {
						let success = false;
						if (cardToRemove.cardId) {
							setIsDeleting(true);
							success = await deleteSavedCard(cardToRemove.cardId);
							queryClient.invalidateQueries("savedCards");
							setIsDeleting(false)
						}

						if (!success) {
							setErrors(["Failed to delete saved card"]);
						}

						setConfirmDialogOpen(false);
					}} autoFocus>
            {isDeleting ? <CircularProgress size="1rem" /> : "Yes"}
          </Button>
        </DialogActions>
      </Dialog>
		</>
	);
};

export default SavedCards;
