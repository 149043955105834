import { TextField } from "@mui/material";
import { ReactElement, useState } from "react";
import { useQueryClient } from "react-query";
import { useUpdateAddressMutation } from "../../../Hooks/Mutations/useUpdateAddressMutation";
import { useErrorMessage } from "../../../Hooks/useErrorMessage";
import { useGiftMessage } from "../../../Hooks/useGiftMessage";
import { Address } from "../../../Types/AddressTypes";
import { Session } from "../../../Types/SessionType";
import { log } from "../../../Utilities/backendRequests";
import GreenButton from "../../GreenButton";
import AddressRadioList from "./AddressRadioList";
import ManualAddressDialog from "./ManualAddressDialog";
interface SavedAddressFormProps {
	addresses: Address[];
	sessionData: Session;
	onAddNewAddress: Function;
	onComplete: (
		selectedAddressIndex: number,
		giftMessage: string,
		phoneNumber: string
	) => Promise<void>;
}

function SavedAddressForm({
	addresses,
	sessionData,
	onAddNewAddress,
	onComplete,
}: SavedAddressFormProps): ReactElement {
	const { errorMessage, setErrors, clearErrors } = useErrorMessage({
		margin: "1em 0",
	});
	const [addressIndex, setAddressIndex] = useState<number | null>(
		sessionData.selectedDeliveryAddress
	);
	const [phoneNumber, setPhoneNumber] = useState<string>(
		sessionData.user.phoneNumber === null ? "" : sessionData.user.phoneNumber
	);

	const [fetching, setFetching] = useState(false);

	const { getGiftMessage, giftMessageComponent } = useGiftMessage(sessionData);

	const [updateManualAddressState, setUpdateManualAddressState] =
		useState(false);

	const queryClient = useQueryClient();

	const { updateAddressMutation } = useUpdateAddressMutation(queryClient);

	function handlePhoneNumberOnChange(
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) {
		setPhoneNumber(event.target.value);
	}

	return (
		<>
			<>
				{errorMessage}
				<TextField
					label="Mobile Phone"
					onChange={handlePhoneNumberOnChange}
					value={phoneNumber}
					helperText="Used only for delivery purpose"
					style={{ margin: "1em 0", width: "100%" }}
					data-testid="savedAddressMobile"
				/>

				<AddressRadioList
					addresses={addresses}
					isEditable={true}
					addressIndex={addressIndex}
					setAddressIndex={setAddressIndex}
					onEdit={(newSelectedAddressIndex) => {
						clearErrors();
						setAddressIndex(newSelectedAddressIndex);
						setUpdateManualAddressState(true);
					}}
				/>

				<GreenButton
					text="+ Add New Address"
					style={{
						marginBottom: "1em",
						background: "transparent",
						color: "#1976D2",
						textAlign: "start",
						width: "",
					}}
					onClick={() => {
						clearErrors();
						log("add-new-address", "click", {
							location: "saved-address-form",
						});
						onAddNewAddress();
					}}
					data-testid="savedAddressAddNewAddress"
				></GreenButton>

				<GreenButton
					loading={fetching}
					disabled={fetching}
					text="Continue"
					style={{ marginTop: "1em" }}
					onClick={async () => {
						clearErrors();
						if (addressIndex === null) {
							setErrors(["A delivery address is required"]);
							setFetching(false);
							return;
						}

						setFetching(true);

						onComplete(
							addressIndex,
							getGiftMessage(),
							phoneNumber === null ? "" : phoneNumber
						).catch(() => setFetching(false));
					}}
				></GreenButton>
			</>

			{updateManualAddressState && (
				<ManualAddressDialog
					open={updateManualAddressState}
					handleClose={() => {
						setUpdateManualAddressState(false);
					}}
					address={sessionData.addresses[addressIndex ?? 0]}
					onAddressEntered={async (address) => {
						clearErrors();
						setFetching(true);
						log("manual-address-enter", "click", {
							address: address,
						});
						await updateAddressMutation({
							address,
							addressIndex: addressIndex!,
						});

						if (addressIndex !== null) {
							try {
								await onComplete(
									addressIndex,
									getGiftMessage() === null ? "" : getGiftMessage(),
									phoneNumber === null ? "" : phoneNumber
								);
							} catch (error) {
								setFetching(false);
							}
						}
						setFetching(false);
					}}
					countryCodes={sessionData.acceptedDeliveryCountries}
				/>
			)}

			{giftMessageComponent}
		</>
	);
}

export default SavedAddressForm;
