import { RadioGroup } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { log } from "../../../Utilities/backendRequests";
import PaymentRadioWithLabel from "./PaymentRadioWithLabel";

type PaymentRadioButtonValue =
	| "card"
	| "paypal"
	| "apple-pay"
	| "google-pay"
	| "cheque"
	| "account";

interface PaymentRadioSelectProps {
	getButtonToShow: (paymentMethod: PaymentRadioButtonValue) => ReactElement;
	showCard?: boolean;
	showPayPal?: boolean;
	showApplePay?: boolean;
	showGooglePay?: boolean;
	showCheque?: boolean;
	showAccount?: boolean;
	children?: React.ReactNode;
	inSplitTest: boolean;
}

const PaymentRadioSelect = ({
	getButtonToShow,
	showCard = true,
	showPayPal = true,
	showApplePay = false,
	showGooglePay = false,
	showCheque = true,
	showAccount = false,
	children,
	inSplitTest,
}: PaymentRadioSelectProps) => {
	const [radio, setRadio] = useState<PaymentRadioButtonValue>(
		showAccount ? "account" : "card"
	);
	const [currentCard, setCurrentCard] = useState(0);

	let cardPaymentImages = ["images/Visa.svg"];

	if (inSplitTest) {
		cardPaymentImages = [
			"images/Visa.svg",
			"images/Mastercard.svg",
			"images/Amex.svg",
		];
	}

	useEffect(() => {
		const interval = setInterval(() => {
			if (currentCard === cardPaymentImages.length - 1) {
				setCurrentCard(0);
				return;
			}

			setCurrentCard((prev) => {
				return prev + 1;
			});
		}, 1500);

		return () => clearInterval(interval);
	});

	return (
		<>
			<RadioGroup
				value={radio}
				name="payment-method"
				onChange={(event) => {
					log("change-payment-method", "click", {
						radioSelect: PaymentRadioSelect,
					});
					setRadio(event.target.value as PaymentRadioButtonValue);
				}}
			>
				{showAccount && (
					<PaymentRadioWithLabel
						value="account"
						label="Account Payment (option to enter PO ref)"
					/>
				)}

				{showCard && (
					<PaymentRadioWithLabel
						image={cardPaymentImages[currentCard]}
						value="card"
						label="Credit / Debit Card"
					/>
				)}
				{showPayPal && (
					<PaymentRadioWithLabel
						image="images/Paypal.svg"
						value="paypal"
						label="PayPal"
					/>
				)}
				{showApplePay && (
					<PaymentRadioWithLabel
						image="images/ApplePay.svg"
						value="apple-pay"
						label="Apple Pay"
						includeBorder={false}
					/>
				)}
				{showGooglePay && (
					<PaymentRadioWithLabel
						image="images/GooglePayBorder.svg"
						value="google-pay"
						label="Google Pay"
						includeBorder={false}
					/>
				)}
				{showCheque && (
					<PaymentRadioWithLabel
						value="cheque"
						label="Bank Transfer / Cheque"
					/>
				)}
			</RadioGroup>

			{children}

			{getButtonToShow(radio)}
		</>
	);
};

export default PaymentRadioSelect;
