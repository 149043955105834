import {
	AdminPanelSettingsTwoTone,
	NoAccountsTwoTone,
} from "@mui/icons-material";
import { ReactElement } from "react";
import { AccountProps } from "../../../Types/CheckoutType";
import AccordionRow from "../../AccordionRow";
import AccountForm from "./AccountForm";
import AccountGuest from "./AccountGuest";

function Account({
	user,
	appState,
	setAppState,
	stateId,
	sessionData,
	expressCheckout,
	deviceData,
}: AccountProps): ReactElement {
	function setToEdit() {
		setAppState({ currentState: stateId, type: "EDIT" });
	}

	function setToSet() {
		setAppState({ currentState: stateId, type: "SET" });
	}

	const currentAppState = appState[stateId].state;
	return (
		<>
			{user.id === null && (
				<AccordionRow
					state={currentAppState}
					icon={
						currentAppState === "SET" && user.type === "guest" ? (
							<NoAccountsTwoTone fontSize="medium" />
						) : (
							<AdminPanelSettingsTwoTone fontSize="medium" />
						)
					}
					typography={
						currentAppState === "SET" && user.type === "guest"
							? "Guest"
							: "Account"
					}
					expandable={
						currentAppState !== "CURRENT" &&
						currentAppState !== "EDIT" &&
						currentAppState !== "FUTURE" &&
						user.type === "guest"
					}
					expandOnClick={setToEdit}
				>
					{currentAppState === "SET" && user.type === "guest" && (
						<AccountGuest user={user} />
					)}

					{(currentAppState === "CURRENT" ||
						currentAppState === "EDIT") && (
						<AccountForm
							completeFunction={setToSet}
							user={user}
							sessionData={sessionData}
							expressCheckout={expressCheckout}
							deviceData={deviceData}
						/>
					)}
				</AccordionRow>
			)}
		</>
	);
}

export default Account;
