
import {
  CircularProgress,
  createTheme,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useQuery } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "../../checkout.css";
import CheckoutContainer from "../CheckoutContainer";
import Basket from "../Groups/Basket/Basket";
import Header from "../Header";
import "../../App.css";
import {  useEffect, useState } from "react";
import CenterScreenMessage from "../CenterScreenMessage";
import { WarningAmber } from "@mui/icons-material";
import AcceptedPaymentMethodsList from "../AcceptedPaymentMethodsList";
import { useIsMobile } from "../../Hooks/useIsMobile";
import { log, setAxiosInterceptors } from "../../Utilities/backendRequests";
import { captureException } from "@sentry/browser";
import { useBraintreeClient } from "../../Hooks/useBraintreeClient";
import { useApplePayBraintree } from "../../Hooks/useApplePayBraintree";
import { useGooglePayBraintree } from "../../Hooks/useGooglePayBraintree";
import { usePaypalBraintree } from "../../Hooks/useBraintreePaypal";
import { Navigate, useParams } from "react-router";
function Checkout() {
	let {token} = useParams();
	token = token ?? ''
	const braintreeHook = useBraintreeClient(token ?? '');
	const applePayHook = useApplePayBraintree(braintreeHook.braintreeClient);
	const googlePayHook = useGooglePayBraintree(braintreeHook.braintreeClient);
	const paypalHook = usePaypalBraintree(braintreeHook.braintreeClient);
	const isMobile = useIsMobile();
	const [sessionGet, setSessionGet] = useState("fetching");

	useEffect(() => {
		if (sessionGet === "success") {
			log("checkout-entered", "click", {});
		}
	}, [sessionGet]);

	window.localStorage.setItem("token", token);
	setAxiosInterceptors(token)

	const { status, data } = useQuery(
		"session",
		async () => {
			const { data } = await axios.get(
				process.env.REACT_APP_BACKEND_API_URL + "/session"
			);

			return data.data.session;
		},
		{
			retry: 1,
			onSuccess() {
				setSessionGet("success");
			},
			onError(data: any) {
				const status = data.response.status;
				if (status === 400 || status === 401) {
					setSessionGet("expired");
				} else {
					setSessionGet("failed");
					captureException(data);
				}
			},
			staleTime: 30000,
		}
	);

	if(token === '') {
		<Navigate to="/"/>
	}

	if ("loading" === status) {

		return (
			<>
				<Header />
				<CenterScreenMessage
					icon={<CircularProgress />}
					message="Loading checkout..."
				/>
			</>
		);
	}

	if (sessionGet === "expired") {
		return (
			<>
				<Header />
				<CenterScreenMessage
					icon={
						<WarningAmber sx={{ color: "orange", fontSize: "3em" }} />
					}
					message="Session timed out!"
					subMessage=" Please go back to your basket and try again."
				></CenterScreenMessage>
			</>
		);
	}

	if (sessionGet === "failed") {
		return (
			<>
				<Header />
				<CenterScreenMessage
					icon={<WarningAmber sx={{ color: "red", fontSize: "3em" }} />}
					message="Sorry, we was unable to find your checkout session."
					subMessage=" Please go back to your basket and try again."
				></CenterScreenMessage>
			</>
		);
	}

	const style: React.CSSProperties = {
		backgroundColor: "#f8f8f3",
		minHeight: "100vh",
	};

	const font = "'Source Sans Pro', sans-serif";

	const theme = createTheme({
		typography: {
			fontFamily: font,
		},
		components: {
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						fontFamily: "'Roboto', sans-serif",
						fontWeight: 400,
					},
				},
			},
		},
	});

	const favicon = document.getElementById(
		"favicon"
	) as HTMLAnchorElement | null;
	if (favicon !== null) {
		favicon.href = data.siteInformation.favicon;
	}
	const addressIsSet = (data.selectedDeliveryAddress !== null || data.collectDeliveryDetails === false) && data.settings.hideTotalPreDeliveryOptions === true;

	return (
		<div style={style}>
			<ThemeProvider theme={theme}>
				<Header
					user={data.user}
					siteInformation={data.siteInformation}
				/>

				<Box className="checkout">
					<CheckoutContainer
						data={data}
						applePayHook={applePayHook}
						googlePayHook={googlePayHook}
						braintreeHook={braintreeHook}
						paypalHook={paypalHook}
					></CheckoutContainer>

					<Box
						style={{
							display: "flex",
							flexDirection: isMobile
								? "column-reverse"
								: "column",
						}}
					>
						<Basket
							costs={data.costs}
							vouchers={data.vouchers}
							items={data.items}
							isGuest={data.user.type === "guest"}
							logoutEndpoint={data.siteInformation.logoutUrl}
							moto={data.settings.transactionSource === "moto"}
							addressIsSet = {addressIsSet}
							hideTotalPreDeliveryOptions = {data.settings.hideTotalPreDeliveryOptions}
						/>
						<AcceptedPaymentMethodsList
							acceptedPayments={data.acceptedPayments}
						/>
					</Box>
				</Box>

				{data.user.reference !== "" && (
					<Box
						style={
							isMobile
								? { textAlign: "right" }
								: { position: "fixed", bottom: 0, right: 0 }
						}
						padding="0.5em"
					>
						<Typography
							style={{ wordBreak: "break-word" }}
							variant="body2"
						>
							{data.user.reference}
						</Typography>
					</Box>
				)}
			</ThemeProvider>
			<ReactQueryDevtools initialIsOpen={false} />
		</div>
	);
}

export default Checkout;
