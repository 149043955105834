import { getSavedCards } from "../Utilities/backendRequests";
import { useQuery } from "react-query";

export const useSavedCards = () => {
	return useQuery(
		["savedCards"],
		getSavedCards,
		{
			staleTime: 3600,
			cacheTime: 3600,
			refetchOnWindowFocus: false,
		}
	)
};
