import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import GreenButton from "../../GreenButton";
import * as Yup from "yup";
import { log } from "../../../Utilities/backendRequests";
import { useIsMobile } from "../../../Hooks/useIsMobile";
import { useEffect } from "react";

type ContinueAsGuestFormProps = {
	email: string;
	setEmail: Function;
	onSubmit: Function;
	style?: React.CSSProperties;
	disableContinue?: boolean;
};

function ContinueAsGuestForm({
	email,
	setEmail,
	onSubmit,
	style,
	disableContinue = false,
}: ContinueAsGuestFormProps) {
	const isMobile = useIsMobile();

	const {
		handleSubmit,
		formState: { errors, isSubmitting },
		watch,
		register,
	} = useForm<{ email: string }>({
		reValidateMode: "onSubmit",
		resolver: yupResolver(
			Yup.object().shape({
				email: Yup.string()
					.email("Please enter a valid email")
					.required("An email is needed for guest checkout"),
			})
		),
		defaultValues: {
			email: email !== undefined ? email : "",
		},
	});
	const watchEmail = watch("email");

	useEffect(() => {
		setEmail(watchEmail);
	}, [watchEmail, setEmail]);

	const defaultStyle: React.CSSProperties = {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		gap: "1em",
	};
	const formStyle = style !== undefined ? style : defaultStyle;

	return (
		<form
			onSubmit={handleSubmit(async (data) => {
				setEmail(data.email);
				await onSubmit(data.email);
			})}
			style={formStyle}
		>
			<FormControl fullWidth>
				<TextField
					{...register("email")}
					label="Your Email"
					type="email"
					error={!!errors.email}
					helperText="An email is needed for guest checkout"
					data-testid="guestEmail"
				></TextField>
			</FormControl>
			<GreenButton
				loading={isSubmitting}
				disabled={isSubmitting || disableContinue}
				type="submit"
				text="Continue as Guest"
				style={{ padding: isMobile ? "1em" : "" }}
				onClick={() => {
					log("continue-guest", "click", { email: watchEmail });
				}}
				data-testid="guestContinue"
			/>
		</form>
	);
}

export default ContinueAsGuestForm;
