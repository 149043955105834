import {
	Box,
	Checkbox,
	Divider,
	FormControlLabel,
	RadioGroup,
	TextField,
	Typography,
} from "@mui/material";
import { useController, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";
import useCurrencyFormatter from "../../../Hooks/useCurrencyFormatter";
import { useErrorMessage } from "../../../Hooks/useErrorMessage";
import { useIsMobile } from "../../../Hooks/useIsMobile";
import { DonationOption } from "../../../Types/DonationTypes";
import { Session } from "../../../Types/SessionType";
import {
	createDonationOption,
	handleAxiosError,
	log,
	updateDonationGiftAid,
	updateSelectedDonationOptions,
} from "../../../Utilities/backendRequests";
import ChipRadio from "../../ChipRadio";
import { yupResolver } from "@hookform/resolvers/yup";

const DonationAmountSelect = ({
	setUpdatingDonations,
	donationName,
	selectedDonationOption,
	donationOptions,
	allowVariableOption,
	giftAid,
	sessionData,
}: {
	sessionData: Session;
	donationName: string;
	selectedDonationOption: number;
	donationOptions: DonationOption[];
	allowVariableOption: boolean;
	giftAid: boolean;
	setUpdatingDonations: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const { clearErrors, errorMessage, setErrors } = useErrorMessage({
		marginBottom: "1em",
	});
	const isMobile = useIsMobile();

	const queryClient = useQueryClient();
	const formatter = useCurrencyFormatter();
	// const roundUpDonationAmount =
	// 	Math.ceil(
	// 		(sessionData.costs.total - sessionData.costs.donationCost) / 10
	// 	) * 10;

	const setDonationMutation = useMutation(
		async ({
			giftAid,
			selectedOptionIndex,
		}: {
			selectedOptionIndex: number;
			giftAid: boolean;
		}) => {
			await updateDonationGiftAid(giftAid);
			const result = await updateSelectedDonationOptions(
				selectedOptionIndex
			);
			return result;
		},
		{
			onSuccess: (data) => {
				queryClient.setQueryData("session", data);
				clearErrors();
			},
			...handleAxiosError(setErrors),
		}
	);

	const otherSchema = Yup.object().shape({
		otherDonation: Yup.string()
			.notRequired()
			.when("selectedOption", {
				is: `${donationOptions.length}`,
				then: () => Yup.number()
					.min(0, "Donation value must be greater than 0")
					.required("A donation amount is required")
					.typeError("Donation value must be greater than 0"),
			}),
		selectedOption: Yup.string().required(),
		giftAid: Yup.boolean(),
	});

	const { control, formState, handleSubmit, watch, setValue } = useForm({
		defaultValues: {
			selectedOption: `${selectedDonationOption}`,
			otherDonation: "",
			giftAid: giftAid,
		},
		resolver: yupResolver(otherSchema),
	});

	const { field: selectedOptionField } = useController({
		control,
		name: "selectedOption",
	});
	const { field: giftAidField } = useController({ control, name: "giftAid" });
	const { field: otherDonationField } = useController({
		control,
		name: "otherDonation",
	});

	const submitForm = handleSubmit(async (data) => {
		try {
			setUpdatingDonations(true);
			let donationOptionIndex = parseInt(data.selectedOption);

			if (
				data.otherDonation &&
				data.otherDonation !== "" &&
				donationOptions[donationOptionIndex] === undefined
			) {
				//Create the new donation option
				try {
					const response = await createDonationOption(
						parseInt(data.selectedOption),
						parseFloat(data.otherDonation),
						formatter.format(parseFloat(data.otherDonation))
					);
					donationOptionIndex = response.selectedDonationOption;
				} catch (error) {
					handleAxiosError(setErrors).onError(error);
					return;
				}
			}

			//Mutate and set our selected donation
			await setDonationMutation.mutateAsync({
				selectedOptionIndex: donationOptionIndex,
				giftAid: data.giftAid ?? false,
			});
		} catch (_) {
			setUpdatingDonations(false);
		} finally {
			setUpdatingDonations(false);
		}
	});

	// const debounceSubmitForm = useMemo(() => debounce(submitForm, 1000), []);

	return (
		<>
			{errorMessage}

			<Box
				display="flex"
				gap="1em"
				style={
					isMobile
						? { flexDirection: "column", alignItems: "start" }
						: { alignItems: "center" }
				}
			>
				<img
					width={112}
					height={56}
					src={sessionData.donation.imageUrl}
					alt={sessionData.donation.name}
					style={{
						width: 112,
						height: "auto",
						...(isMobile ? { float: "left" } : {})
					}}
				/>
				<Typography>{sessionData.donation.text}</Typography>
			</Box>
			<RadioGroup
				{...selectedOptionField}
				onChange={async (e) => {
					log("donation-option-change", "click", {});
					selectedOptionField.onChange(e);
					await submitForm(e);
				}}
				row
			>
				{donationOptions.map((donation, index) => {
					return (
						<ChipRadio
							key={index}
							value={index}
							label={donation.text}
						/>
					);
				})}

				{allowVariableOption && (
					<>
						{/* <ChipRadio
							key="round"
							value="round"
							label={"Round my order to " + formatter.format(roundUpDonationAmount)}
							onClick={() => {
								log("donation-round", "click", {});
								setValue(
									"otherDonation",
									roundUpDonationAmount -
										(sessionData.costs.total - sessionData.costs.donationCost) +
										""
								);
							}}
						/> */}
						<ChipRadio
							key={donationOptions.length}
							value={donationOptions.length}
							label="Other Amount"
							onClick={() => {
								log("donation-other-option", "click", {});
								setValue("otherDonation", "");
							}}
						/>
					</>
				)}
			</RadioGroup>

			{watch("selectedOption") === `${donationOptions.length}` && (
				<TextField
					{...otherDonationField}
					error={!!formState.errors.otherDonation}
					fullWidth
					helperText={formState.errors.otherDonation?.message}
					label="Enter donation amount"
					onClick={() => {
						log("donation-other-option", "click", {});
					}}
					onBlur={(event) => {
						submitForm(event);
					}}
				/>
			)}

			{watch("selectedOption") !== "0" && (
				<>
					<Divider style={{ margin: "1em 0" }} />
					<FormControlLabel
						control={
							<Checkbox
								name={giftAidField.name}
								checked={giftAidField.value}
								onChange={async (event) => {
									giftAidField.onChange(event);
									await submitForm(event);
								}}
							/>
						}
						label={`Agree to your name, email and billing address being shared with ${donationName} for Gift Aid and administration purposes`}
					/>
				</>
			)}
		</>
	);
};
export default DonationAmountSelect;
