import axios from "axios";
import { Address } from "../Types/AddressTypes";
import { Session } from "../Types/SessionType";

export const sessionShippingMethodsToPaypal = (
	sessionData: Session
): paypal.ShippingOption[] => {
	// @ts-ignore: TODO complaining about the type
	return sessionData.deliveryOptions.map((deliveryOption, index) => {
		return {
			id: index.toString(),
			amount: {
				value: deliveryOption.cost.toFixed(2),
				currency: "GBP",
			},
			label: deliveryOption.description,
			selected: index === sessionData.selectedDeliveryOption,
			type: "SHIPPING",
		};
	});
};

export const convertPaypalAddressToBackendFormat = (
	address: paypal.Address
): Address => {
	return {
		addressLine1: address.line1 ?? "",
		addressLine2: address.line2 ?? "",
		city: address.city ?? "",
		countryCode: address.countryCode ?? "",
		postcode: address.postalCode ?? "",
		fullName: address.recipientName ?? "",
	};
};

export const updatePaypalPrice = async (orderId: string, price: number) => {
	await axios.put(
		process.env.REACT_APP_BACKEND_API_URL + "/session/paypal/price",
		{
			orderId,
			price,
		}
	);
};
