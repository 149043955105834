import { Grid } from "@mui/material";
import { ReactElement } from "react";
import { UserType } from "../../../Types/UserType";
import EmailRegisteredNotice from "../../EmailRegisteredNotice";

interface AccountGuestProps {
	user: UserType;
}

function AccountGuest({ user }: AccountGuestProps): ReactElement {
	const registeredDetected = user.type === "registered";

	return (
		<>
			<Grid container>
				<small style={{ marginLeft: "2.5em" }}>{user.email}</small>
				{registeredDetected && <EmailRegisteredNotice />}
			</Grid>
		</>
	);
}

export default AccountGuest;
