import { ImageListItem } from "@mui/material";
import { ReactElement } from "react";
import { PaymentMethodIcon } from "../Types/PaymentTypes";

interface PaymentMethodListProps {
	iconUrls: PaymentMethodIcon[];
}

const PaymentMethodList = ({
	iconUrls,
}: PaymentMethodListProps): ReactElement => {
	const style = {
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex",
	};

	return (
		<div style={style}>
			{iconUrls.map((iconUrl, index) => (
				<ImageListItem key={index}>
					<img
						style={{
							objectFit: "contain",
							width: "48px",
							height: "36px",
							borderRadius: 4,
						}}
						src={iconUrl.url}
						alt={iconUrl.alt}
						loading="lazy"
						width={48}
						height={36}
					/>
				</ImageListItem>
			))}
		</div>
	);
};

export default PaymentMethodList;
